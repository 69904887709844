import { orderBy } from 'lodash';
import { GeoCityStats, GeoStateStats } from '.';

export interface PerformanceStats {
  categories: any[];
  channels: any[];
  vendors: any[];
  retailers: any[];
  states: any[];
  cities: any[];
  revenueOverTime: {
    current: any[],
    previous: any[],
  };
  isAggregate: boolean;
  isCurrent: boolean;
  geoDisplayable: boolean;
  salesDataDisplayable: boolean;
  historicalData: any[];
}

export const DefaultPerformanceStats: PerformanceStats = {
  geoDisplayable: true,
  salesDataDisplayable: true,
  categories: [],
  channels: [],
  vendors: [],
  retailers: [],
  states: [],
  cities: [],
  revenueOverTime: {
    current: [],
    previous: [],
  },
  isAggregate: false,
  isCurrent: false,
  historicalData: [],
};

export interface VendorRetailerStats {
  name: string;
  id: number;
  image: string;
  amount: number;
  previousAmount: number;
  percent: Number;
  rpu: Number;
  units: Number;
  sales: number;
}

export const apiToPerformanceStats = (data: any): PerformanceStats => {
  const channels = orderBy(data.channels.map((channelItem: any) => {
    return {
      channel: { id: channelItem.id, name: channelItem.title },
      units: channelItem.quantity,
      royalties: Number(channelItem.amount),
      sales: Number(channelItem.gross_sales),
      percent: channelItem.percent *  100,
      averageRPU: Number(channelItem.rpu),
    };
  }),                      ['royalties'], ['desc']);
  const categories = orderBy(data.categories.map((categoryItem: any) => {
    return {
      category: {
        id: categoryItem.id,
        name: categoryItem.title,
      },
      averageRPU: categoryItem.rpu,
      sales: Number(categoryItem.gross_sales),
      units: categoryItem.quantity,
      royalties: Number(categoryItem.amount),
      percent: categoryItem.percent *  100,
    };
  }),                        ['royalties'], ['desc']);

  const states: GeoStateStats[] = orderBy(data.states.map((state: any) => {
    return {
      state: state.title,
      fullState: state.full_state,
      amount: Number(state.amount),
      sales: Number(state.gross_sales),
      percent: state.percent * 100,
      rpu: Number(state.rpu),
      units: Number(state.quantity),
    };
  }),                                     ['amount'], ['desc']);
  const cities: GeoCityStats[] = orderBy(data.cities.map((city: any) => {
    return {
      city: city.title,
      amount: Number(city.amount),
      sales: Number(city.gross_sales),
      percent: city.percent *  100,
      rpu: Number(city.rpu),
      units: Number(city.quantity),
    };
  }),                                    ['amount'], ['desc']);

  const vendors: VendorRetailerStats[] = orderBy(data.licensees.map((v: any) => {
    return {
      name: v.title,
      id: v.id,
      image: v.image,
      amount: Number(v.amount),
      previousAmount: v.previous ?  Number(v.previous.amount) : 0,
      percent: v.percent * 100,
      rpu: Number(v.rpu),
      units: Number(v.quantity),
      sales: Number(v.gross_sales),
    };
  }),                                            ['amount'], ['desc']);
  const retailers: VendorRetailerStats[] = orderBy(data.retailers.map((v: any)  => {
    return {
      name: v.title,
      id: v.id,
      image: v.image,
      amount: Number(v.amount),
      previousAmount: v.previous ?  Number(v.previous.amount) : 0,
      percent: v.percent * 100,
      rpu: Number(v.rpu),
      units: Number(v.quantity),
      sales: Number(v.gross_sales),
    };
  }),                                              ['amount'], ['desc']);

  const revenueOverTime = data.revenue_over_time ? data.revenue_over_time : { previous : [], current: [] };
  revenueOverTime.previous = revenueOverTime.previous.map((p: any) => {
    return {
      month: p.month,
      royalties: Number(p.royalties),
      grossSales: Number(p.gross_sales),
    };
  });
  revenueOverTime.current = revenueOverTime.current.map((c: any) => {
    return {
      month: c.month,
      royalties: Number(c.royalties),
      grossSales: Number(c.gross_sales),
    };
  });

  const historicalData = data.historical_data ? data.historical_data.map((d: any) => {
    return { year: d.year, royalties: Number(d.royalties.toFixed(2)), grossSales: Number(d.gross_sales.toFixed(2)) };
  }) : [];

  return {

    categories,
    channels,
    states,
    cities,
    vendors,
    retailers,
    revenueOverTime,
    historicalData,
    isAggregate: data.is_aggregate,
    isCurrent: data.is_current,
    salesDataDisplayable: data.displayable.includes('nongeo'),
    geoDisplayable: data.displayable.includes('geo'),
  };

};
