import Axios from 'axios';
import * as querystring from 'query-string';
import * as React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { DistributionChannel } from '../../shared';
import { FullContent } from '../ContentFrame';
import { DistributionChannelsInfoModal } from '../Royalties/RoyaltyAnalytics/DistributionChannelsInfoModal';
import { useCancelToken } from '../shared';
import { HiddenGeoDataModule, HiddenSalesDataModule } from './HiddenPerformanceModule';
import { PerformanceTitleFilters } from './PerformanceTitleFilters';
import { CategoryPerformance, ChannelPerformance, GeolocationPerformance, HistoricalPerformance, PerformanceStats, RetailerPerformance, VendorPerformance } from './submodules';
import { apiToPerformanceStats, DefaultPerformanceStats } from './submodules/shared';
import { usePerformanceQueryParams } from './usePerformanceQueryParams';

export const ChannelsPerformancePage = () => {

  const [stats, setStats] = React.useState<PerformanceStats>(DefaultPerformanceStats);
  const [loading, setLoading] = React.useState(true);
  const [distChannels, setDistChannels] = React.useState<DistributionChannel[]>([]);
  const [modalShown, setModalShown] = React.useState(false);
  const location = useLocation();
  const query = querystring.parse(location.search);
  const performanceQP = usePerformanceQueryParams();
  const isFiltered = query['channel'] ? true : false;
  const cancelToken = useCancelToken();

  React.useEffect(() => {
    getData();
    getDistChannels();
    performanceQP.scrollToTop();

  },              [location.search]);

  const getData = async () => {
    if (cancelToken) {
      cancelToken.cancelToken();
    }
    setLoading(true);
    const token = Axios.CancelToken.source();
    cancelToken.setToken(token);
    const queryParams = performanceQP.toApi('channel');
    const analytics = await Axios.get(`/api/performance-reports/sales-data?${queryParams}`, { cancelToken: token.token })
      .catch((e) => {
        setLoading(false);
        cancelToken.setToken(null);
      });
    if (analytics) {
      const s = apiToPerformanceStats(analytics.data.data);
      setStats(s);
      setLoading(false);
    }

    cancelToken.setToken(null);
  };

  const getDistChannels = async () => {
    const d = await Axios.get('/api/distributionChannels');
    const c = d.data.data.map((channel: any) => new DistributionChannel(channel));
    setDistChannels(c);
  };

  let additionalGraphs;
  if (isFiltered) {
    additionalGraphs = (
      <div>
        {/* { !stats.isAggregate ?
          <div className="row">
            <div className="col-md-6">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <RevenueOverTime
                    current={stats.revenueOverTime.current}
                    previous={stats.revenueOverTime.previous}
                    loading={loading}
                    type="sales"
                  />
                </div>
              </div>

            </div>
            <div className="col-md-6">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <RevenueOverTime
                    current={stats.revenueOverTime.current}
                    previous={stats.revenueOverTime.previous}
                    loading={loading}
                    type="royalties"
                  />
                </div>
              </div>

            </div>
          </div>
          : null} */}

        { stats.salesDataDisplayable ? <div>
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <VendorPerformance
                    vendors={stats.vendors}
                    loading={loading}
                    large={false}
                    isAggregate={stats.isAggregate}
                  />
                </div>
              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <CategoryPerformance
                    categories={stats.categories}
                    loading={loading}
                    large={false}
                    isAggregate={stats.isAggregate}
                  />
                </div>
              </div>

            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <RetailerPerformance
                    retailers={stats.retailers}
                    loading={loading}
                    large={false}
                    isAggregate={stats.isAggregate}
                  />
                </div>
              </div>

            </div>
          </div>
        </div> :
        <div className="panel panel-portal">
          <div className="panel-body">
            <HiddenSalesDataModule />
          </div>
        </div> }
        {stats.geoDisplayable ? <div>

          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <GeolocationPerformance
                    large={false}
                    loading={loading}
                    states={stats.states}
                    cities={stats.cities}
                    section="map"
                    isAggregate={stats.isAggregate}
                  />

                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <GeolocationPerformance
                    large={false}
                    loading={loading}
                    states={stats.states}
                    cities={stats.cities}
                    section="states"
                    isAggregate={stats.isAggregate}
                  />

                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <GeolocationPerformance
                    large={false}
                    loading={loading}
                    states={stats.states}
                    cities={stats.cities}
                    section="cities"
                    isAggregate={stats.isAggregate}
                  />

                </div>
              </div>
            </div>
          </div>

        </div> :
        <div className="panel panel-portal">
          <div className="panel-body">
            <HiddenGeoDataModule />
          </div>
        </div> }
      {stats.salesDataDisplayable && performanceQP.isVendorFiltered() ? <div className="row">
        <div className="col-md-12">
          <div className="panel panel-portal">
            <div className="panel-body" style={{ textAlign: 'center' }}>
              <h4>Royalty Report Data Export</h4>
              <p className="text-muted">Export royalty report data for this vendor.</p>
              <Link target="_blank" to={`/api/royalty-analytics/vendors/${performanceQP.params.vendor}/downloads?${performanceQP.toApi('download')}`}>
                <button className="btn btn-default">
                  <i className="fa fa-download" /> Download Export
                  </button>
              </Link>
            </div>
          </div>
        </div>

      </div> : null}

      </div>
    );
  }

  return (
    <FullContent>
      <PerformanceTitleFilters
        title="Channels"
        subTitle="Sales by distribution channel."
        isAggregate={stats.isAggregate}
        isCurrent={stats.isCurrent}
        info={true}
        infoOnClick={() => setModalShown(true)}
      />

      <DistributionChannelsInfoModal onClose={() => setModalShown(false)} shown={modalShown} channels={distChannels} />
      {(stats.salesDataDisplayable && isFiltered) || loading ? <div className="panel panel-portal">
        <div className="panel-body">
          <HistoricalPerformance
            loading={loading}
            historicalData={stats.historicalData}
          />
        </div>
      </div> : null}
      {(stats.salesDataDisplayable || isFiltered) || loading ? <div className="panel panel-portal">
        <div className="panel-body">
          <ChannelPerformance
            channels={stats.channels}
            loading={loading}
            large={true}
            isAggregate={stats.isAggregate}
          />
        </div>
      </div> :
      <div className="panel panel-portal">
        <div className="panel-body">
          <HiddenSalesDataModule />
        </div>
      </div>
      }
      {additionalGraphs}
    </FullContent>
  );

};
