import Axios from 'axios';
import * as querystring from 'query-string';
import * as React from 'react';
import { useLocation } from 'react-router';
import { FullContent } from '../ContentFrame';
import { useCancelToken } from '../shared';
import { HiddenGeoDataModule, HiddenSalesDataModule } from './HiddenPerformanceModule';
import { PerformanceTitleFilters } from './PerformanceTitleFilters';
import { CategoryPerformance, ChannelPerformance, GeolocationPerformance, HistoricalPerformance, PerformanceStats, RetailerPerformance, VendorPerformance } from './submodules';
import { apiToPerformanceStats, DefaultPerformanceStats } from './submodules/shared';
import { usePerformanceQueryParams } from './usePerformanceQueryParams';

export const GeolocationPage = () => {
  const [stats, setStats] = React.useState<PerformanceStats>(DefaultPerformanceStats);
  const [loading, setLoading] = React.useState(true);
  const location = useLocation();
  const query = querystring.parse(location.search);
  const performanceQP = usePerformanceQueryParams();
  const isFiltered = query['state'] ? true : false;
  const cancelToken = useCancelToken();

  React.useEffect(() => {
    getData();
    performanceQP.scrollToTop();
  },              [location.search]);

  const getData = async () => {
    if (cancelToken) {
      cancelToken.cancelToken();
    }
    setLoading(true);
    const token = Axios.CancelToken.source();
    cancelToken.setToken(token);
    const queryParams = performanceQP.toApi('geo');
    const analytics = await Axios.get(`/api/performance-reports/sales-data?${queryParams}`, { cancelToken: token.token })
      .catch((e) => {
        setLoading(false);
        cancelToken.setToken(null);
      });
    if (analytics) {
      const s = apiToPerformanceStats(analytics.data.data);
      setStats(s);
      setLoading(false);
    }

  };

  let additionalGraphs;
  if (isFiltered) {
    additionalGraphs = (
      <div>
        {/* { !stats.isAggregate ?
          <div className="row">
            <div className="col-md-6">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <RevenueOverTime
                    current={stats.revenueOverTime.current}
                    previous={stats.revenueOverTime.previous}
                    loading={loading}
                    type="sales"
                  />
                </div>
              </div>

            </div>
            <div className="col-md-6">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <RevenueOverTime
                    current={stats.revenueOverTime.current}
                    previous={stats.revenueOverTime.previous}
                    loading={loading}
                    type="royalties"
                  />
                </div>
              </div>

            </div>
          </div>
          : null} */}

        {stats.salesDataDisplayable ? <div>
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <VendorPerformance
                    vendors={stats.vendors}
                    loading={loading}
                    large={false}
                    isAggregate={stats.isAggregate}
                  />
                </div>
              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <CategoryPerformance
                    categories={stats.categories}
                    loading={loading}
                    large={false}
                    isAggregate={stats.isAggregate}
                  />
                </div>
              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <ChannelPerformance
                    channels={stats.channels}
                    loading={loading}
                    large={false}
                    isAggregate={stats.isAggregate}
                  />
                </div>
              </div>

            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <RetailerPerformance
                    retailers={stats.retailers}
                    loading={loading}
                    large={false}
                    isAggregate={stats.isAggregate}
                  />
                </div>
              </div>

            </div>
          </div>

        </div> :
          <div className="panel panel-portal">
            <div className="panel-body">
              <HiddenSalesDataModule />
            </div>
          </div>

        }

      </div>
    );
  }

  return (

    <FullContent>
      <PerformanceTitleFilters
        title="Geolocation"
        subTitle="Sales reported by geolocation."
        isAggregate={stats.isAggregate}
        isCurrent={stats.isCurrent}
      />
      {(stats.salesDataDisplayable && isFiltered) || loading ? <div className="panel panel-portal">
        <div className="panel-body">
          <HistoricalPerformance
            loading={loading}
            historicalData={stats.historicalData}
          />
        </div>
      </div> : null}
      {(stats.geoDisplayable || isFiltered) || loading ? <div>

        <div className="panel panel-portal">
          <div className="panel-body">
            <GeolocationPerformance
              singleState={query['state']}
              cities={stats.cities}
              states={stats.states}
              loading={loading}
              large={true}
              isAggregate={stats.isAggregate}
              section="map"
            />
          </div>
        </div>
        <div className="panel panel-portal">
          <div className="panel-body">
            <GeolocationPerformance
              singleState={query['state']}
              cities={stats.cities}
              states={stats.states}
              loading={loading}
              large={true}
              isAggregate={stats.isAggregate}
              section="states"
            />
          </div>
        </div>
        <div className="panel panel-portal">
          <div className="panel-body">
            <GeolocationPerformance
              singleState={query['state']}
              cities={stats.cities}
              states={stats.states}
              loading={loading}
              large={true}
              isAggregate={stats.isAggregate}
              section="cities"
            />
          </div>
        </div>

      </div> :
        <div className="panel panel-portal">
          <div className="panel-body">
            <HiddenGeoDataModule />
          </div>
        </div>
      }
      {additionalGraphs}
    </FullContent>

  );

};
