import Axios from 'axios';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { DropdownButton, MenuItem, Modal } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router';
import { AffinityImage, Design, Filters, ProductCategory, Vendor } from '../../shared';
import { FullContent } from '../ContentFrame';
import { RoyaltiesTab } from '../Designs';
import {
  GatewayModal,
  LoadingSpinner,
  ModalType,
  PaginationFooter,
  ProductCategoryTypeahead,
  VendorTypeahead,
} from '../shared';
import { TopSellingDesignGridItem } from '../shared/grid/TopSellingDesignGridItem';
import { PerformanceTitleFilters } from './PerformanceTitleFilters';

interface Paginator {
  totalDesigns: number;
  page: number;
  lastPage: boolean;
  totalPages: number;
}

export const PerformanceDesignTopSellingPage = () => {
  const [loading, setLoading] = React.useState(true);
  const [designs, setDesigns] = React.useState<Design[]>([]);
  const [infoModalShown, setInfoModalShown] = React.useState(false);
  const [advancedSearchModalShown, setAdvancedSearchModalShown] = React.useState(false);
  const [salesModalShown, setSalesModalShown] = React.useState(false);
  const [selectedVendors, setSelectedVendors] = React.useState<Vendor[]>([]);
  const [selectedRetailers, setSelectedRetailers] = React.useState<Vendor[]>([]);
  const [selectedCategories, setSelectedCategories] = React.useState<ProductCategory[]>([]);
  const [salesDesignId, setSalesDesignId] = React.useState(0);
  const [salesDesign, setSalesDesign] = React.useState<Design>(new Design());
  const [pagination, setPagination] = React.useState<Paginator>({
    totalDesigns: 0,
    page: 0,
    lastPage: false,
    totalPages: 0,
  });

  const location = useLocation();
  const history = useHistory();
  const routeParams = useParams();
  let query = queryString.parse(location.search);
  const filters = new Filters();
  filters.setQueryParamsFromLocation(location.search);

  React.useEffect(() => {
    query = queryString.parse(location.search);
    if (query.vendorIds) {
      setSelectedVendors(query.vendorIds.split(',').map((v: any) => new Vendor({ id: v, short_name: 'Loading' })));
    } else {
      setSelectedVendors([]);
    }
    if (query.retailerIds) {
      setSelectedRetailers(query.retailerIds.split(',').map((v: any) => new Vendor({ id: v, short_name: 'Loading' })));
    } else {
      setSelectedRetailers([]);
    }
    if (query.categoryIds) {
      setSelectedCategories(query.categoryIds.split(',').map((v: any) => new ProductCategory({ id: v, name: 'Loading' })));
    } else {
      setSelectedCategories([]);
    }
    getDesigns();
  },              [location.search]);

  const openSalesModal = (id: number) => {
    setSalesDesignId(id);
    const selectedDesign = designs.find(d => d.id === Number(id));
    if (selectedDesign) {
      setSalesDesign(selectedDesign);
    }
    setSalesModalShown(true);
  };

  const getDesigns = async () => {
    setLoading(true);
    setDesigns([]);
    query = queryString.parse(location.search);
    const params = {
      account_id: getAccountID(),
      page: query.page,
      keyword: query.search,
      vendors: query.vendorIds,
      retailers: query.retailerIds,
      categories: query.categoryIds,
      year: query.year,
      quarter: query.quarter,
      sort: query.sort ? query.sort : 'royalties',
    };
    const response = await Axios.get('/api/performance-reports/designs/top-selling', { params });
    setDesigns(response.data.data.data.map((design: any) => Design.fromApi(design)));
    const paginationResult = response.data.data.meta.pagination;
    setPagination({
      totalDesigns: paginationResult.total,
      page: paginationResult.current_page,
      lastPage: paginationResult.total_pages === paginationResult.current_page,
      totalPages: paginationResult.total_pages,
    });
    setLoading(false);
  };

  const searchDesigns = async (values: any, actions: FormikActions<any>) => {
    setSelectedVendors(values.vendors);
    setSelectedRetailers(values.retailers);
    setSelectedCategories(values.categories);
    let currentParams = queryString.parse(location.search);
    const vendorIds = values.vendors.length ? values.vendors.map((d: any) => d.id).join(',') : null;
    const retailerIds = values.retailers.length ? values.retailers.map((d: any) => d.id).join(',') : null;
    const categoryIds = values.categories.length ? values.categories.map((d: any) => d.id).join(',') : null;
    currentParams = { ...currentParams, vendorIds, retailerIds, categoryIds };
    const baseUrl = location.pathname;
    const cleaned = omitBy(currentParams, isNil);
    history.replace(`${baseUrl}?${queryString.stringify(cleaned)}`);
    setAdvancedSearchModalShown(false);
  };

  const setSort = (type: any) => {
    const currentParams = queryString.parse(location.search);
    currentParams.sort = type.toLowerCase();
    const cleaned = omitBy(currentParams, isNil);

    const baseUrl = location.pathname;
    history.replace(`${baseUrl}?${queryString.stringify(cleaned)}`);
  };

  const setPage = (newPage: number) => {
    const temp = pagination;
    temp.page = newPage;
    setPagination(temp);
    let currentParams = queryString.parse(location.search);
    currentParams = { ...currentParams, page: newPage };
    const cleaned = omitBy(currentParams, isNil);

    const baseUrl = location.pathname;
    history.replace(`${baseUrl}?${queryString.stringify(cleaned)}`);
  };

  function getAccountID() {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['licensorId']) {
      return routeParams['licensorId'];
    }
    return '';
  }

  const backupImage = new AffinityImage();
  backupImage.defaultImage = 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/vendor-icon.png';

  const designsGrid = designs && designs.length === 0 ? <em>No Designs Found</em> :
    designs.map((design:Design, index) => {
      return (
          <div key={design.id} className="col-xl-2 col-lg-3 col-md-4 col-sm-4">
            <TopSellingDesignGridItem
              url={`/designs/${design.id}`}
              image={design.primaryIteration && design.primaryIteration.image ? design.primaryIteration.image.getSize('sm') : backupImage.getSize('sm')}
              vendorImage={design.vendor && design.vendor.image ? design.vendor.image.getSize('sm') : backupImage.getSize('sm')}
              netSales={design.netSales ? design.netSales : 0}
              royaltySales={design.royaltySales ? design.royaltySales : 0}
              quantity={design.quantity ? design.quantity : 0}
              design={design}
              salesOnClick={openSalesModal}
            >
            </TopSellingDesignGridItem>
          </div>
      );
    });

  const sortTypes = ['sales', 'royalties', 'units'];
  let currentSort = 'Royalties';
  if (query.sort && sortTypes.includes(query.sort.toLowerCase())) {
    currentSort = query.sort[0].toUpperCase() + query.sort.substring(1);
  }

  return (
    <FullContent>
      <div className="row" style={{ paddingBottom: 10 }} >
        <PerformanceTitleFilters
          hideFilters={false}
          title="Top Selling"
          subTitle="Track top selling products."
          isCurrent={false}
          isAggregate={false}
          info={true}
          infoOnClick={() => setInfoModalShown(true)}
        >

          <div className="pull-right">
            <DropdownButton
              style={{ marginRight: 15 }}
              id="sale-dropdown"
              bsStyle={'default'}
              title={currentSort}
              onSelect={setSort}
            >
              <MenuItem eventKey="Royalties"> Royalties </MenuItem>
              <MenuItem eventKey="Sales"> Sales </MenuItem>
              <MenuItem eventKey="Units"> Units </MenuItem>
            </DropdownButton>
          </div>
          <button className="btn btn-default pull-right" style={{ marginRight: 15 }} onClick={() => setAdvancedSearchModalShown(true)}>
            Filters
          </button>
        </PerformanceTitleFilters>

      </div>
      <div className="row">
        {!loading ? designsGrid : <LoadingSpinner/>}
      </div>
      {!loading && designs && designs.length > 0 && <PaginationFooter
        totalResults={pagination.totalDesigns}
        hasNext={!pagination.lastPage}
        currentPage={pagination.page}
        setPage={setPage}
        totalPages={pagination.totalPages}
        showFirstLast={true}
      />}

      <GatewayModal
        type={ModalType.Primary}
        shown={advancedSearchModalShown}
        onClose={() => setAdvancedSearchModalShown(false)}
        title={'Filter'}
      >
        <Formik initialValues={{
          vendors: selectedVendors ? selectedVendors : [],
          retailers: selectedRetailers ? selectedRetailers : [],
          categories: selectedCategories ? selectedCategories : [],
        }}
                onSubmit={searchDesigns}>

          {(formProps: FormikProps<any>) => (
            <Form>
              <Modal.Body>
                <div className="form-group">
                  <label>Vendor</label>
                  <VendorTypeahead selected={formProps.values.vendors} onChange={v => formProps.setFieldValue('vendors', v)} multiple={true} />
                </div>
                <div className="form-group">
                  <label>Retailer</label>
                  <VendorTypeahead isRetailers={true} selected={formProps.values.retailers} onChange={r => formProps.setFieldValue('retailers', r)} multiple={true} />
                </div>
                <div className="form-group">
                  <label>Product Category</label>
                  <ProductCategoryTypeahead selected={formProps.values.categories} onChange={c => formProps.setFieldValue('categories', c)} multiple={true} />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button onClick={() => setAdvancedSearchModalShown(false)} className="btn btn-default">Close</button>
                <button className="btn btn-primary pull-right">Search</button>
              </Modal.Footer>
            </Form>

          )}

        </Formik>
      </GatewayModal>

      <GatewayModal
        type={ModalType.Primary}
        shown={infoModalShown}
        onClose={() => setInfoModalShown(false)}
        title={'About Top Selling Data'}
      >
        <Modal.Body>
          <p>
            Top selling products are determined by linking design submissions with royalty report sales data through an
             Affinity UPI. For this to work the vendor must report a recognized UPI in their royalty report sales data.
             Vendors began reporting UPIs in mid-2022. Top selling will continue to improve as more vendors report recognized UPIs.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setInfoModalShown(false)} className="btn btn-default">
            Close
          </button>
        </Modal.Footer>
      </GatewayModal>

      <GatewayModal
        type={ModalType.Primary}
        shown={salesModalShown}
        onClose={() => setSalesModalShown(false)}
        title={'Sales Data'}
        size={'large'}
      >
        <Modal.Body>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            flexDirection: 'column',
          }}>
            <h4 className="text-center">{ salesDesign && salesDesign.vendor ? salesDesign.vendor.shortName : null}</h4>
            { salesDesign && salesDesign.vendor && salesDesign.vendor.image  ?
              <img style={{ minHeight: 50, maxHeight: 50 }} className="img-responsive" src={salesDesign.vendor.image.getSize('sm')} /> :
              <img style={{ minHeight: 50, maxHeight: 50 }} className="img-responsive" src={backupImage.getSize('sm')} />}
          </div>
          <RoyaltiesTab designId={salesDesignId} clientId={getAccountID()}/>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setSalesModalShown(false)} className="btn btn-default">
            Close
          </button>
        </Modal.Footer>
      </GatewayModal>
    </FullContent>
  );
};
