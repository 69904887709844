import { faFilePlus } from '@fortawesome/pro-regular-svg-icons';
import { faExternalLink, faFilePdf, faFileVideo, faInfoCircle, faMinus, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import { uniq, upperFirst } from 'lodash';
import * as moment from 'moment';
import * as queryString from 'query-string';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { UserContext } from '../../../contexts';
import { AffinityClient, Design, ProductCategory } from '../../../shared';
import { filenameWithoutExtension } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { AffinityDropzone } from '../../Navigation';
import { GatewayModal, LicensorTypeahead, LoadingSpinner, ModalType, ProductCategoryTypeahead } from '../../shared';
import { ExpediteDesignModal } from './ExpediteDesignModal';

export const SingleDesignUploadPage = () => {
  const user = React.useContext(UserContext);
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [expediteModalShown, setExpediteModalShown] = React.useState(false);
  const [checkingDesign, setCheckingDesign] = React.useState(false);
  const [submittingDesign, setSubmittingDesign] = React.useState(false);
  const [duplicateDesigns, setDuplicateDesigns] = React.useState<{upi: string, design: Design}[]>([]);
  const [duplicateModalShown, setDuplicateModalShown] = React.useState(false);

  React.useEffect(() => {

  },              []);

  const locationClientCategory = () => {
    const s = queryString.parse(location.search);
    const cat = s['category'] ? s['category'].split(',') : null;
    return { licensor: s['licensor'], category: cat };

  };

  const generateRID = (formProps: FormikProps<any>, field: string) => {
    let uid = uuidv4();
    uid = uid.split('-')[0];
    uid = uid.slice(0, 6).toUpperCase();
    const lid = formProps.values.licensor[0].id;
    const date = moment();
    const dateCode = date.format('MMDDYY');
    const combined = `${lid}-${uid}-${dateCode}`;
    formProps.setFieldValue(field, combined);
  };

  const fileTypeVisual = (type: string, url: string) => {
    if (type === 'pdf') {
      return (
        <div style={{ marginBottom: 20 }}>
          <FontAwesomeIcon style={{ fontSize: 48 }} icon={faFilePdf} />
        </div>
      );
    }
    if (type === 'mp4') {
      return (
        <div style={{ marginBottom: 20 }}>
          <FontAwesomeIcon style={{ fontSize: 48 }} icon={faFileVideo} />
        </div>
      );
    }
    return (
      <img style={{ maxHeight: 400 }} className="img-responsive" src={url} />
    );
  };

  const requiredFields = (formProps: FormikProps<any>) => {
    const currentDate = new Date();
    const dateToEnable = new Date('4/1/2022');
    const isMarketingMaterials = formProps.values.product.length > 1 ? false : formProps.values.product.reduce((prev: boolean, c: ProductCategory) => {
      if (c.name.toLowerCase() === 'marketing materials') {
        return true;
      }
      return prev;
    },                                                                                                         false);

    return (
      <div style={{ display: formProps.values.licensor.length && formProps.values.title && formProps.values.product.length ? 'block' : 'none' }}>
        {!isMarketingMaterials ? <div>
          {currentDate.getTime() >= dateToEnable.getTime() ?
            <FieldArray
              name="upi"
              render={helpers => (
                <div>
                  <label style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>Unique Product Identifier (UPI) <a className="text-muted" target="_blank" href="https://help.affinitylicensing.com/en/articles/6008552-unique-product-identifiers-upi">
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </a></span>
                    <span style={{ cursor: 'pointer' }} className="text-primary" onClick={() => helpers.push('')}>
                      <FontAwesomeIcon className="text-primary" icon={faPlusCircle} /> UPI
                    </span>

                  </label>
                  {formProps.values.upi.map((u: string, index: number) => (
                    <div className="form-group">
                      <div className="input-group">
                        <Field className="form-control" name={`upi.${index}`} />
                        <div className="input-group-btn">
                          <button onClick={() => u.length ? formProps.setFieldValue(`upi.${index}`, '') : generateRID(formProps, `upi.${index}`)} type="button" className="btn btn-default">{u.length ? 'Clear' : 'Generate'}</button>
                          {formProps.values.upi.length > 1 ?
                          <button onClick={() => helpers.remove(index)} type="button" className="btn btn-default"><FontAwesomeIcon className="text-danger" icon={faMinus} /></button> : null}
                        </div>
                      </div>
                      <p className="text-danger">
                        <ErrorMessage name={`upi.${index}`} />
                      </p>
                    </div>

                  ))}
                </div>
              )}

            /> : null}
          <div className="form-group">
            <label>Please select the applicable distribution channels for this order:</label>
            <p className="text-danger">
              <ErrorMessage name="channelDirect" />
            </p>
            <div className="checkbox">
              <label>
                <Field checked={formProps.values.channelDirect} type="checkbox" name="channelDirect" /> Direct to Consumer
              </label>
            </div>
            <div className="checkbox">
              <label>
                <Field checked={formProps.values.channelRetail} type="checkbox" name="channelRetail" /> Business to Business (Wholesale/Retail)
              </label>
            </div>
            {formProps.values.licensor.length && formProps.values.licensor[0].market.id === 2 ?
              <div className="checkbox">
                <label>
                  <Field checked={formProps.values.channelInternal} type="checkbox" name="channelInternal" /> Internal Consumption
                </label>
              </div> : null}

            <p className="text-danger">
              <ErrorMessage name="channel" />
            </p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <div className="form-group">
                  <label>Customer Name</label>
                  <Field placeholder="Customer Name" name="customer" className="form-control" />
                  <p className="text-danger">
                    <ErrorMessage name="customer" />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Estimated Units</label>
                <Field placeholder="Estimated Units" name="units" className="form-control" />
                <p className="text-danger">
                  <ErrorMessage name="units" />
                </p>
              </div>
            </div>
          </div>
          <p className="text-danger">
            <ErrorMessage name="internalCustomerUnits" />
          </p>
          <div style={{ display: formProps.values.channelInternal ? 'block' : 'none' }}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Are university funds being used for this order?</label>
                  <div>
                    <label className="radio-inline">
                      <Field type="radio" name="universityFunds" value={true} /> Yes
                    </label>
                    <label className="radio-inline">
                      <Field type="radio" name="universityFunds" value={false} /> No
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  <ErrorMessage name="universityFunds" />
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>Is the product being given away? (not for resale)</label>
                  <div>
                    <label className="radio-inline">
                      <Field type="radio" name="notForResale" value={true} /> Yes
                    </label>
                    <label className="radio-inline">
                      <Field type="radio" name="notForResale" value={false} /> No
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  <ErrorMessage name="notForResale" />
                </p>
              </div>
            </div>
          </div>

        </div> : null}
        <div>
          <div className="form-group">
            <label>Submission Note</label>
            <Field placeholder="Submission Note" component="textarea" className="form-control" name="note" />
          </div>
          <label>Expedited Review <span className="text-primary">$3.75</span></label>
          <p>
            You can pay to expedite this submission for faster review.
            Expedited submissions are typically reviewed in less than 24 hours.
          </p>
          <div style={{ marginBottom: 10 }}>
            {formProps.values.expedite ? <button
              type="button"
              onClick={() => formProps.setFieldValue('expedite', false)}
              className="btn btn-default"
            >
              Cancel Expedited Review
            </button> : <button
              type="button"
              onClick={() => setExpediteModalShown(true)}
              className="btn btn-default"
            >
              Expedite Review
            </button>}
          </div>
          <button style={{ marginTop: 20 }} className="btn btn-primary btn-block">
            {submittingDesign ? 'Submitting...' : 'Submit for Review'}
          </button>
          <hr />

          <label>Multiple Submission Options</label>
          <p>Do you want to continue submitting additional designs?</p>

          <div className="row">
            <div className="col-md-6">
              <div className="checkbox">
                <label>
                  <Field checked={formProps.values.reuseLicensor} type="checkbox" name="reuseLicensor" /> Select same licensor
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="checkbox">
                <label>
                  <Field checked={formProps.values.reuseCategory} type="checkbox" name="reuseCategory" /> Select same category
                </label>
              </div>

            </div>
          </div>
          <button type="submit" onClick={() => formProps.setFieldValue('resubmit', true)} className="btn btn-default btn-block">
            {submittingDesign ? 'Submitting...' : 'Submit and Start New Submission'}
          </button>

        </div>

      </div>
    );
  };

  const duplicateDesignModal = (formProps: FormikProps<any>) => {
    if (!duplicateDesigns.length) {
      return null;
    }

    const designIds = uniq(duplicateDesigns.map(d => d.design.id));

    const duplicateDesign = designIds.length === 1 ? duplicateDesigns[0].design : null;

    const errorMessages = duplicateDesigns.map(d => `${d.upi} is linked with another design for ${formProps.values.licensor.length && d.design.hasLicensor(Number(formProps.values.licensor[0].id)) ? 'this' : 'another' } licensor.`);

    let firstOption;
    if (duplicateDesign) {
      if (formProps.values.licensor.length && !duplicateDesign.hasLicensor(Number(formProps.values.licensor[0].id))) {
        firstOption = errorMessages.map(e => <p className="text-danger">{e}</p>);

      } else {
        firstOption = <button onClick={async () => {
          if (!duplicateDesign) {
            return;
          }
          const values = formProps.values;
          const formData = new FormData();
          formData.append('image', values.design, values.design.name);
          formData.append('image_filename', values.design.name);

          values.product.forEach((p: ProductCategory) => formData.append('categories[]', `${p.id}`));
          await Axios.post(`/api/designs/${duplicateDesign.id}/iterations`, formData);
          if (user.type === 'admin' && match) {
            history.push(`/vendors/${match.params['vendorId']}/designs`);
          } else {
            history.push('/designs');
          }
        }}
          className="btn btn-primary">
          Link to Original Submission
        </button>;

      }

    } else {
      firstOption = errorMessages.map(e => <p className="text-danger">{e}</p>);
    }

    return (
      <Modal.Body>
       {duplicateDesign ? <div className="row">
          <div className="col-md-6">
            <h5 className="text-center"><Link target="_blank" to={`/designs/${duplicateDesign.id}`}>Previous Submission <FontAwesomeIcon icon={faExternalLink} /></Link></h5>
            <img className="img-responsive" src={duplicateDesign.primaryIteration.image.getSize('md')} />
          </div>
          <div className="col-md-6">
            <h5 className="text-center">New Submission</h5>
            <img className="img-responsive" src={formProps.values.designPreview} />
          </div>
        </div> : null}
       {duplicateDesign ? <hr /> : null}

        <h5><strong>Option 1: Link Submissions</strong></h5>

        <p>If these submissions are related and should use this same unique product identifier, you can link the new submission with the original submission.</p>
        {firstOption}
        <hr />

        <h5><strong>Option 2: Use Different UPI</strong></h5>
        <p>If these submissions are not related and should have different unique product identifiers, you can go back and enter a different UPI. </p>
        <button onClick={() => setDuplicateModalShown(false)} className="btn btn-primary">Go Back, Use Different UPI</button>

      </Modal.Body>
    );

  };

  const loc = locationClientCategory();

  return (
    <FullContent>
      <Formik
        initialValues={{
          licensor: loc.licensor ?  [new AffinityClient({ id: loc.licensor, short_name: 'Loading' })] : [],
          design: null,
          designPreview: '',
          title: '',
          product: loc.category ? loc.category.map((c: any) => {
            return { id: c, name: 'Loading' };
          }) : [],
          note: '',
          channelDirect: false,
          channelRetail: false,
          channelInternal: false,
          internalCustomerUnits: '',
          universityFunds: null,
          notForResale: null,
          upi: [''],
          retailer: '',
          customer: '',
          units: '',
          expedite: false,
          resubmit: false,
          reuseLicensor: true,
          reuseCategory: true,
        }}
        validationSchema={Yup.object().shape({
          licensor: Yup.array().min(1, 'A primary licensor is required').required(''),
          design: Yup.mixed().required('A file is required'),
          title: Yup.string().required('A title is required'),
          product: Yup.array().min(1, 'A product category is required.').required(''),
          units: Yup.number().typeError('Estimated units must be a number'),
          channelRetail: Yup.boolean().notRequired(),
          channelInternal: Yup.boolean().notRequired(),
          customer: Yup.string().when('licensor', (licensor: any, schema: any) => {
            return licensor.length > 0 ? schema.notOneOf([licensor[0].name, licensor[0].shortName], 'Customer must not be the licensor') : undefined;
          }),
          internalCustomerUnits: Yup.mixed().when(['channelInternal', 'customer', 'units'], {
            is: (channelInternal, customer, units) => {
              return channelInternal && (!customer || !units);
            },
            then: Yup.mixed().required('Customer name and estimated units are required for internal orders.'),
            otherwise: Yup.mixed().notRequired(),
          }),
          channelDirect: Yup.boolean().when(['channelRetail', 'channelInternal', 'product'], {
            is: (channelRetail, channelInternal, product) => {
              return channelRetail || channelInternal || (product.length === 1 && product
                .reduce((prev: boolean, c: ProductCategory) => {
                  if (c.name.toLowerCase() === 'marketing materials') {
                    return true;
                  }
                  return prev;
                },      false));
            },
            then: Yup.boolean().notRequired(),
            otherwise: Yup.boolean().required('required').oneOf([true], 'A channel is required.'),
          }),
          universityFunds: Yup.mixed().when(['channelInternal'], {
            is: (channelInternal) => {
              return channelInternal;
            },
            then: Yup.boolean().required('Answer is required.').typeError('Answer is required.'),
            otherwise: Yup.mixed().notRequired(),
          }),
          notForResale: Yup.mixed().when(['channelInternal'], {
            is: (channelInternal) => {
              return channelInternal;
            },
            then: Yup.boolean().required('Answer is required.').typeError('Answer is required.'),
            otherwise: Yup.mixed().notRequired(),
          }),
          upi: Yup.array().when(['product'], {
            is: (product) => {
              return product.length > 1 ? false : product
                .reduce((prev: boolean, c: ProductCategory) => {
                  if (c.name.toLowerCase() === 'marketing materials') {
                    return true;
                  }
                  return prev;
                },      false);
            },
            then: Yup.array().notRequired(),
            otherwise: Yup.array().of(
              Yup.string()
                .required('UPI is required')
                .max(255, 'Must be shorter than 255 characters')
                .test(
                  'non-allowed-chars',
                  'The following characters are not allowed: , ;',
                  value => !value.includes(',') && !value.includes(';'),
                ),
              )
              .min(1, 'UPI is required.'),

          }),
        })}
        onSubmit={async (values: any, actions) =>  {
          if (!submittingDesign) {
            setSubmittingDesign(true);
            const formData = new FormData();
            formData.append('primary_client_id', values.licensor[0].id);
            formData.append('title', values.title);
            if (values.note) {
              formData.append('description', values.note);
            }
            formData.append('image', values.design, values.design.name);
            formData.append('image_filename', values.design.name);
            if (values.upi.length && values.upi[0].length) {
              let upi = values.upi.filter((u: string) => u.length);
              upi = uniq(upi); // filters duplicates
              upi.forEach((u: string) => formData.append('upi[]', u));
            }
            formData.append('product_category_id', values.product[0].id);

            values.product.forEach((p: ProductCategory) => formData.append('product_categories[]', `${p.id}`));
            formData.append('is_expedited', values.expedite ? '1' : '0');
            if (values.units) {
              formData.append('unit_estimation', values.units);
            }
            formData.append('channel_internal', values.channelInternal ? '1' : '0');
            formData.append('channel_direct', values.channelDirect ? '1' : '0');
            formData.append('channel_retail', values.channelRetail ? '1' : '0');
            if (values.customer) {
              formData.append('customer', values.customer);
            }
            if (user.type === 'admin' && match) {
              formData.append('vendor_account_id', match.params['vendorId']);
            }
            if (values.universityFunds !== null && values.channelInternal) {
              formData.append('university_funds', values.universityFunds === 'true' ? '1' : '0');
            }
            if (values.notForResale !== null && values.channelInternal) {
              formData.append('not_for_resale', values.notForResale === 'true' ? '1' : '0');
            }
            const d = await Axios.post('/api/designs/upload', formData)
              .catch((e) => {
                if (e.response && e.response.status === 409) {
                  const duplicates = e.response.data.duplicates.map((d: any) => {
                    return {
                      upi: d.upi,
                      design: Design.fromApi(d.design),
                    };
                  });
                  setDuplicateDesigns(duplicates);
                  setDuplicateModalShown(true);

                }
                return false;
              });
            setSubmittingDesign(false);
            if (d) {
              if (values.resubmit) {
                actions.resetForm();
                let qs = '?';
                if (values.reuseLicensor) {
                  actions.setFieldValue('licensor', values.licensor);
                  qs = `?licensor=${values.licensor[0].id}`;
                } else {
                  actions.setFieldValue('licensor', []);
                }
                if (values.reuseCategory) {
                  actions.setFieldValue('product', values.product);
                  const cs = values.product.map((p: any) => p.id).join(',');
                  qs += `${qs.length > 1 ? '&' : ''}category=${cs}`;
                } else {
                  actions.setFieldValue('product', []);
                }

                history.push(`${history.location.pathname}${qs.length > 1 ? qs : ''}`);

              } else {
                if (user.type === 'admin' && match) {
                  history.push(`/vendors/${match.params['vendorId']}/designs`);
                } else {
                  history.push('/designs');
                }

              }

            }

          }

        }}
      >
        {(formProps: FormikProps<any>) => (
          <Form>
            <h3><strong>New Submission</strong></h3>
            <p className="help-block">Upload an individual file to start a new submission.
              Files must be a supported file type to be accepted.
              Do not upload a revision/resubmission to a previous submission.
              If you need to upload a revision/resubmission,
              first view the existing submission and then select New Version to upload your revision.
            </p>
            <div className="panel panel-portal">
              <div className="panel-body">
                <div className="row">
                  <div className="col-md-6">
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
                      <div style={{ borderRadius: '100%', width: 31, fontSize: 21, marginRight: 10 }} className="bg-primary text-center">
                        <strong>1</strong>
                      </div>
                      <h4 className="text-primary">
                         <strong>Upload Individual File</strong>
                      </h4>
                    </div>
                    <AffinityDropzone
                      accept="image/*,application/pdf,audio/mp4,video/mp4,application/mp4"
                      multiple={false}
                      onDropAccepted={async (files) => {
                        setCheckingDesign(true);

                        const file = files[0];
                        const preview = URL.createObjectURL(file);
                        formProps.setFieldValue('designPreview', preview);
                        formProps.setFieldValue('design', file);
                        const i = await Axios.get('/api/designs/inferred-client', { params: { name: file.name } })
                          .catch(e => undefined);
                        if (i) {
                          const l = new AffinityClient(i.data.data);
                          l.name = i.data.data.name;
                          l.shortName = i.data.data.name;
                          formProps.setFieldValue('licensor', [l]);

                        }
                        const title = filenameWithoutExtension(file.name, true);
                        const cleanedTitle = title.split(' ').map(s => upperFirst(s)).join(' ');
                        formProps.setFieldValue('title', cleanedTitle);
                        setCheckingDesign(false);

                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        {!formProps.values.design ? <div style={{ marginBottom: 20 }}>
                          <FontAwesomeIcon style={{ fontSize: 32 }} icon={faFilePlus} />
                        </div> :
                        fileTypeVisual(formProps.values.design.name.split('.').pop(), formProps.values.designPreview)}
                        {formProps.values.design ?
                          <p className="text-muted">{formProps.values.design.name}</p> :
                          <p className="text-center text-muted">Supported files: jpg, jpeg, png, pdf</p>}
                        <button className="btn btn-primary" type="button">Select File</button>
                      </div>

                    </AffinityDropzone>

                  </div>
                  <div className="col-md-6">
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
                      <div style={{ borderRadius: '100%', width: 31, fontSize: 21, marginRight: 10 }} className="bg-primary text-center">
                        <strong>2</strong>
                      </div>
                      <h4 className="text-primary">
                        <strong>Submission Details</strong>
                      </h4>
                    </div>
                    {formProps.values.design && !checkingDesign ? <div>
                      <div className="form-group">
                        <label>Title</label>
                        <Field placeholder="Title" className="form-control" name="title" />
                        <p className="text-danger">
                          <ErrorMessage name="title" />
                        </p>
                      </div>
                      <div className="form-group">
                        <label>Primary Licensor</label>
                        <LicensorTypeahead
                          selected={formProps.values.licensor}
                          onChange={(l) => {
                            formProps.setFieldValue('licensor', l);
                            formProps.setFieldValue('retailer', '');
                            formProps.setFieldValue('channel', '');
                            formProps.setFieldValue('retailer', '');
                            formProps.setFieldValue('units', '');
                            formProps.setFieldValue('upi', ['']);
                            formProps.setFieldValue('expedite', false);
                            formProps.setFieldValue('channelDirect', false);
                            formProps.setFieldValue('channelRetail', false);
                            formProps.setFieldValue('channelInternal', false);
                          }}
                          multiple={false}
                          filter={ c => c.profile.acceptingDesigns }
                        />
                        <p className="text-danger">
                          <ErrorMessage name="licensor" />
                        </p>
                      </div>
                      <div className="form-group">
                        <label>Category</label>

                        <ProductCategoryTypeahead
                          multiple={true}
                          selected={formProps.values.product}
                          onChange={c => formProps.setFieldValue('product', c)}

                        />
                      </div>
                      {formProps.values.licensor.length && formProps.values.title && formProps.values.product.length ?
                      null
                      : <h5 className="text-primary text-center"><strong>Complete the form above to continue</strong></h5>}
                      {requiredFields(formProps)}

                    </div> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} className="text-primary">
                          {checkingDesign ? <LoadingSpinner /> : <h5><strong>Upload a file to continue</strong></h5>}

                      </div>}
                  </div>
                </div>
              </div>
            </div>

            <p className="text-muted text-center">
              If you are interested in mass uploading and your business has software development resources,
              please reach out to our team and request more information about our API integration service.

            </p>

            <ExpediteDesignModal
              isShown={expediteModalShown}
              confirm={() => {
                formProps.setFieldValue('expedite', true);
                setExpediteModalShown(false);
              }}
              canExpedite={true}
              close={() => setExpediteModalShown(false)}

            />
            {duplicateDesigns.length ? (
              <GatewayModal
                shown={duplicateModalShown}
                title="UPI Already Exists"
                onClose={() => setDuplicateModalShown(false)}
                type={ModalType.Primary}
              >
                {duplicateDesignModal(formProps)}

              </GatewayModal>
            ) : null}

          </Form>

        )}

      </Formik>
    </FullContent>
  );

};
