import { faBadgeDollar, faChartPie, faHashtag, faSackDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { LoadingSpinner } from '../../shared';
import { ChartFooter } from '../ChartFooter';
import { ChartHeader } from '../ChartHeader';
import { ChartItemList } from '../ChartItemList';
import { usePerformanceQueryParams } from '../usePerformanceQueryParams';
import { VendorRetailerStats } from './shared';

interface IProps {
  large: boolean;
  link?: string;
  retailers: VendorRetailerStats[];
  loading: boolean;
  isAggregate: boolean;
  onDashboard?: boolean;
  page?: number;
}

export const RetailerPerformance = (props: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const performanceQP = usePerformanceQueryParams();

  if (props.loading) {
    return (<LoadingSpinner />);
  }

  const list = props.retailers.map((r) => {
    return {
      percent: r.percent,
      name: r.name,
      vendor: r.name,
      image: r.image,
      royalties: r.amount,
      previousRoyalties: r.previousAmount,
      royaltyComparison: r.amount - r.previousAmount,
      percentComparison: r.previousAmount > 0 ? ((r.amount - r.previousAmount) / r.previousAmount) * 100 : 0,
      units: r.units,
      rpu: r.rpu,
      sales: r.sales,
    };
  });
  const handleClick = (index: number) => {
    const vendor = props.retailers[index];
    if (vendor) {
      const queryString = performanceQP.toQueryString([{ value: vendor.id, label: 'retailer' }]);
      if (props.onDashboard) {

        history.push(`${location.pathname}/retailers?${queryString}`);
      } else {

        history.push(`${location.pathname}?${queryString}`);
      }
    }
  };

  // const labels: any = props.onDashboard ? [{ vendor: '' }, { percent: 'Percent' }] : [
  //   { percent: '' },
  //   { vendor: '' },
  //   { royalties: 'Royalties' },
  //   { previousRoyalties: 'Previous Year' },
  //   { royaltyComparison: '$ Comparison' },
  //   { percentComparison: '% Comparison' },
  // ];
  const labels: any = props.onDashboard ?
    [{ vendor: '' }, { percent: 'Percent' }] :
  [
      { vendor: '' },
      { percent: <span><FontAwesomeIcon className="text-muted" icon={faChartPie} /> Percent</span> },
      { units: <span><FontAwesomeIcon className="text-muted" icon={faHashtag} /> Units</span> },
      { sales: <span><FontAwesomeIcon className="text-muted" icon={faBadgeDollar} /> Sales</span> },
      { royalties: <span><FontAwesomeIcon className="text-muted" icon={faSackDollar} /> Royalties</span> },
  ];
  if (props.large) {

    return (
      <div>
        <ChartItemList
          listVisualStyle="none"
          items={list}
          itemLabels={labels}
          showLabels={true}
          onClick={handleClick}
          overrideIndex={true}
          indexPage={props.page}
        />
      </div>
    );
  }

  return (
    <div>
      <ChartHeader header="Retailers" subHeader="Sales by recognized retailer." />
      <ChartItemList
        listVisualStyle="none"
        items={list}
        itemLabels={labels}
        showLabels={!props.onDashboard}
        onClick={handleClick}
      />
      {props.retailers.length < 5 ? null : <ChartFooter isPartial={props.isAggregate} link={performanceQP.createLink('retailers')} />}
    </div>
  );

};
