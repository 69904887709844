import { faBadgeDollar, faChartPie, faHashtag, faSackDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartOptions } from 'chart.js';
import * as React from 'react';
import { Pie } from 'react-chartjs-2';
import { useHistory, useLocation } from 'react-router';
import { toUSD } from '../../../utils';
import { LoadingSpinner } from '../../shared';
import { ChartFooter } from '../ChartFooter';
import { ChartHeader } from '../ChartHeader';
import { ChartItemList } from '../ChartItemList';
import { CustomBarChart } from '../CustomBarChart';
import { usePerformanceQueryParams } from '../usePerformanceQueryParams';

interface IProps {
  large: boolean;
  link?: string;
  categories: CategoryPerformanceStats[];
  loading: boolean;
  isAggregate: boolean;
  onDashboard?: boolean;
}

interface CategoryPerformanceStats {
  category: {
    id: number,
    name: string,
  };
  averageRPU: number;
  units: number;
  royalties: number;
  percent: number;
  sales: number;
}

export const CategoryPerformance = (props: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const performanceQp = usePerformanceQueryParams();

  const chartOptions: ChartOptions =  {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    tooltips: {
      mode: 'single',
      callbacks: {
        label: (tooltip: any, data: any) => {
          if (tooltip.datasetIndex !== undefined || tooltip.index !== undefined) {
            const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
            const category = data.labels[tooltip.index];
            return `${category}: ${toUSD(value)}`;
          }
          return '';
        },

        footer: (tooltipArray: any, data: any) => {
          const tooltip = tooltipArray[0];
          if (tooltip.datasetIndex !== undefined || tooltip.index !== undefined) {
            const totalData = data.datasets[tooltip.datasetIndex].data
              .reduce((total: number, d: number) => total + d, 0);
            const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
            const percent = ((value / totalData) * 100).toFixed(2);
            return `${percent}%`;
          }
          return '';

        },
      },
    },
    plugins: {
      colorschemes: {
        scheme: 'tableau.MillerStone11',
      },
    },
  };

  if (props.loading) {
    return (<LoadingSpinner />);
  }
  const list = props.categories.map((c) => {
    return {
      percent: c.percent,
      rpu: c.averageRPU,
      units: c.units,
      royalties: c.royalties,
      name: c.category.name,
      sales: c.sales,
    };
  });
  const handleClick = (index: number) => {
    const category = props.categories[index];
    if (category) {
      const queryString = performanceQp.toQueryString([{ value: category.category.id, label: 'category' }]);
      if (props.onDashboard) {
        history.push(`${location.pathname}/categories?${queryString}`);
      } else {
        history.push(`${location.pathname}?${queryString}`);
      }
    }

  };

  const labels: any = props.onDashboard ? [{ name: 'Name' }, { percent: 'Percent' }] :
  [
    { name: '' },
    { percent: <span><FontAwesomeIcon className="text-muted" icon={faChartPie} /> Percent</span> },
    { units: <span><FontAwesomeIcon className="text-muted" icon={faHashtag} /> Units</span> },
    { sales: <span><FontAwesomeIcon className="text-muted" icon={faBadgeDollar} /> Sales</span> },
    { royalties: <span><FontAwesomeIcon className="text-muted" icon={faSackDollar} /> Royalties</span> },
  ];

  if (props.large) {

    const pieData = {
      labels: props.categories.map(stats => stats.category.name),
      datasets: [{
        data: props.categories.map(stats => stats.royalties),
      }],
    };

    return (
      <div>

        {list.length > 1 ?
        <div style={{ height: 300, maxHeight: 300 }}>
            <Pie
              options={chartOptions}
              data={pieData}
              onElementsClick={e => e && e[0] && handleClick(e[0]._index)}
            />
          </div>
        : null }

        <ChartItemList
          listVisualStyle="dot"
          items={list}
          itemLabels={labels}
          showLabels={true}
          useShowAll={true}
          onClick={handleClick}

        />

      </div>
    );
  }

  return (
    <div>
      <ChartHeader header="Categories" subHeader="Sales by product/service category." />

      {list.length && !props.onDashboard ? <CustomBarChart data={list.map(l => l.percent)} id="categories" /> : null}
      <ChartItemList
        listVisualStyle={props.onDashboard ? 'none' : 'dot'}
        items={list}
        itemLabels={labels}
        showLabels={!props.onDashboard}
        onClick={handleClick}
      />

      {props.categories.length < 5 ? null : <ChartFooter isPartial={props.isAggregate} link={performanceQp.createLink('categories')} />}

    </div>
  );

};
