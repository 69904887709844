import { faCalendar, faInfoCircle, faSpinner, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as querystring from 'query-string';
import * as React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import { usePerformanceQueryParams } from '.';
import { formattedFiscalYear } from '../../utils';
import { PartialRepresentation } from '../Royalties/RoyaltyAnalytics/PartialRepresentation';

interface IProps {
  title: string;
  subTitle: string;
  isAggregate: boolean;
  isCurrent: boolean;
  hideFilters?: boolean;
  hideQuarter?: boolean;
  yearsOverride?: number[];
  info?: boolean;
  infoOnClick?: () => void;
  children?: React.ReactNode;

}

interface ParamLabels {
  value: any;
  name: string;
  key: string;
}

export const PerformanceTitleFilters = (props: IProps) => {
  const [loading, setLoading] = React.useState(true);
  const [paramLabels, setParamLabels] = React.useState<ParamLabels[]>([]);
  const location = useLocation();
  const history = useHistory();
  const performanceQP = usePerformanceQueryParams();
  let query = querystring.parse(location.search);

  React.useEffect(() => {
    query = querystring.parse(location.search);
    getLabels();

  },              [location.search]);

  const createActionButton = (key: string, value: string) => {
    const filterLabel = getFilterLabel(key, value);
    return (
        <button
          style={{ marginLeft: 10 }}
          className="btn btn-primary"
          onClick={() => removeFilter(key, value)}
        >
          <FontAwesomeIcon icon={faTimes} /> &nbsp; {filterLabel}
        </button>
    );

  };

  const getLabels = async () => {
    setLoading(true);
    const qs = performanceQP.toApi('topselling');
    try {
      const response = await Axios.get(`/api/performance-reports/queryLabels?${qs}`);
      const data = response.data.data;
      const pl = [];
      for (const key of Object.keys(data)) {
        if (Array.isArray(data[key])) {
          for (const vendor of data[key]) {
            pl.push({
              key,
              name: vendor.name,
              value: vendor.value,
            });
          }
        } else {
          pl.push({
            key,
            name: data[key].name,
            value: data[key].value,
          });
        }
      }
      setParamLabels(pl);
    } catch (error) {
      console.error('Error fetching labels:', error);
    }
    setLoading(false);
  };

  const getFilterLabel = (key: any, value: string) => {
    if (['state'].includes(key)) {
      return value;
    }
    const paramLabel = paramLabels.find(l => l.key === key && String(l.value) === String(value));
    if (paramLabel) {
      return paramLabel.name;
    }
    if (loading) {
      return <FontAwesomeIcon icon={faSpinner} spin />;
    }
    return value;
  };

  const removeFilter = (key: 'category' | 'categoryIds' | 'channel' | 'year' | 'quarter' | 'state' | 'vendor' | 'vendorIds' | 'retailer' | 'retailerIds', idToRemove?: string) => {
    const currentParams = queryString.parse(location.search);
    if (idToRemove && (key === 'vendorIds' || key === 'retailerIds' || key === 'categoryIds')) {
      const ids = typeof currentParams[key] === 'string' ? currentParams[key].split(',') : [];
      let updatedIds = ids.filter((id:string) => id !== idToRemove);
      if (updatedIds.length === 0) {
        delete currentParams[key];
        updatedIds = null;
      } else {
        currentParams[key] = updatedIds.join(',');
      }
      updatedIds = updatedIds ? updatedIds.join(',') : null;
      const updatedParams = { ...currentParams, [key]: updatedIds };

      const cleaned = omitBy(updatedParams, isNil);
      const qs = querystring.stringify(cleaned);
      history.push(`${location.pathname}?${qs}`);
    } else {
      const qs = performanceQP.toQueryString([{ label: key, value: null }]);
      history.push(`${location.pathname}?${qs}`);
    }

  };

  const setQuarter = (quarter: any) => {
    const q = quarter !== 'All' ? quarter : null;
    const qs = performanceQP.toQueryString([{ label: 'quarter', value: q }]);
    history.push(`${location.pathname}?${qs}`);
  };

  const setYear = (year: any) => {
    const qs = performanceQP.toQueryString([{ label: 'year', value: year }]);
    history.push(`${location.pathname}?${qs}`);
  };

  const filters = Object.keys(query).filter(k => !['page', 'year', 'quarter', 'sort'].includes(k)).flatMap((k: string) => {
    const value = query[k];
    if (k === 'vendorIds' || k === 'retailerIds' || k === 'categoryIds') {
      return typeof value === 'string' ? value.split(',').map((id) => {
        const label = paramLabels.find(label => label.value === parseInt(id, 10));
        const name = label ? label.name : id;
        return createActionButton(k, name);
      }) : [];
    }
    // For other filters
    const label = paramLabels.find(label => label.value === value);
    const name = label ? label.name : value;
    return [createActionButton(k, name)];
  });
  const years = [];
  const date = new Date();
  let year;
  if (date.getMonth() >= 9) {
    year = date.getFullYear();
  } else {
    year = date.getFullYear() - 1;
  }

  const currentYearFilter = query['year'] || formattedFiscalYear(year);
  const currentQuarterFilter = query['quarter'] || 'All';
  if (props.yearsOverride) {
    props.yearsOverride.forEach((y) => {
      const yearValue = formattedFiscalYear(y);
      years.push(<MenuItem key={yearValue} eventKey={yearValue} >{yearValue}</MenuItem>);
    });

  } else {
    for (let i = 0; i <= (year - 2018); i++) {
      const yearToAdd = 2018 + i;
      const yearValue = formattedFiscalYear(yearToAdd);

      years.push(<MenuItem key={yearValue} eventKey={yearValue} >{yearValue}</MenuItem>);
    }
  }
  const info = props.info ?
    <FontAwesomeIcon
      style={{ cursor: 'pointer', fontSize: 16 }}
      className="text-primary"
      icon={faInfoCircle}
      onClick={props.infoOnClick}
    /> : null;

  return (
    <div style={{ marginBottom: 20 }}>
      <h3 className="no-margin"><strong>{props.title} {info}</strong></h3>
      <p className="text-muted small">{props.subTitle}</p>
      {props.isAggregate ? <PartialRepresentation /> : null}
      {props.hideFilters ?  null : <div>
        {props.hideQuarter ? null : <DropdownButton
          onSelect={setQuarter}
          id="quarter-dropdown"
          bsStyle={'default'}
          title={currentQuarterFilter}
        >
          <MenuItem eventKey="All" > All </MenuItem>
          <MenuItem divider />
          <MenuItem eventKey="Q1"> Q1 </MenuItem>
          <MenuItem eventKey="Q2"> Q2 </MenuItem>
          <MenuItem eventKey="Q3"> Q3 </MenuItem>
          <MenuItem eventKey="Q4"> Q4 </MenuItem>

        </DropdownButton> }
        <DropdownButton
          style={{ marginLeft: 10 }}
          id="year-dropdown"
          bsStyle={'default'}
          title={currentYearFilter}
          onSelect={setYear}
        >
          {years}

        </DropdownButton>
        {filters}
        {props.children}
      </div>}
      {props.isCurrent ? <div
        className="alert alert-danger small"
        style={{ marginTop: 15, color: 'white', padding: 8, background: '#e06026' }}
      >
        <FontAwesomeIcon icon={faCalendar} /> &nbsp;
        The selected period is in progress. Analytics will adjust as sales are reported,
        and comparisons are hidden until the period is complete.
      </div> : null}
    </div>
  );
};
