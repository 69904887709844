import { isNil, omitBy } from 'lodash';
import * as querystring from 'query-string';
import * as React from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { formattedFiscalYear } from '../../utils';

interface PerformanceQueryParams {
  licensorId: number | null;
  page: number;
  category: number | null;
  channel: number | null;
  year: number | null;
  quarter: string | null;
  state: string | null;
  vendor: number | null;
  retailer: number | null;
  vendorIds: string | null;
  retailerIds: string | null;
  categoryIds: string | null;
}

export interface AdditionalQP  {
  value: any;
  label: 'category' | 'categoryIds' | 'channel' | 'year' | 'quarter' | 'state' | 'vendor' | 'retailer' | 'page' | 'retailerIds' | 'vendorIds';
}

export const usePerformanceQueryParams = () => {
  const location = useLocation();
  const match = useRouteMatch();
  const date = new Date();
  let year: number;
  if (date.getMonth() >= 9) {
    year = date.getFullYear();
  } else {
    year = date.getFullYear() - 1;
  }
  const [params, setQueryParams] = React.useState<PerformanceQueryParams>({
    year,
    licensorId: (match && match.params['licensorId']) || null,
    page: 1,
    category: null,
    channel: null,
    quarter: null,
    state: null,
    vendor: null,
    retailer: null,
    vendorIds: null,
    retailerIds: null,
    categoryIds: null,
  });

  const getYear = (fiscalYear: string) => {
    return fiscalYear.split('-')[0];
  };

  React.useEffect(() => {
    const p = querystring.parse(location.search);
    const currentParams = params;
    currentParams.page = p.page ? Number(p.page) : 1;
    currentParams.category = p.category ? Number(p.category) : null;
    currentParams.channel = p.channel ? Number(p.channel) : null;
    currentParams.year = p.year ? Number(getYear(p.year)) : year;
    currentParams.quarter = p.quarter ? p.quarter : null;
    currentParams.state = p.state ? p.state : null;
    currentParams.vendor = p.vendor ? Number(p.vendor) : null;
    currentParams.retailer = p.retailer ? Number(p.retailer) : null;

    if (p.retailerIds) {
      currentParams.retailerIds = p.retailerIds;
    } else {
      currentParams.retailerIds = null;
    }

    if (p.vendorIds) {
      currentParams.vendorIds = p.vendorIds;
    } else {
      currentParams.vendorIds = null;
    }
    if (p.categoryIds) {
      currentParams.categoryIds = p.categoryIds;
    } else {
      currentParams.categoryIds = null;
    }
    setQueryParams(currentParams);

  },              [location.search]);

  const toApi = (dimension: string, additional?: AdditionalQP[]) => {
    const filters = {
      dimension,
      account_id: params.licensorId,
      year: params.year,
      channel_id: params.channel,
      category_id: params.category,
      page: params.page,
      quarter: params.quarter && params.quarter.charAt(1) || null,
      state: params.state,
      retailer_id: params.retailer,
      vendor_id: params.vendor,

    };
    if (additional) {
      additional.forEach((a) => {
        filters[a.label] = a.value;
      });
    }
    if (dimension === 'download') {
      filters['client_id'] = params.licensorId;
    }
    if (dimension === 'topselling') {
      filters['vendor_ids'] = params.vendorIds;
      filters['retailer_ids'] = params.retailerIds;
      filters['category_ids'] = params.categoryIds;
    }
    const cleaned = omitBy(filters, isNil);
    return querystring.stringify(cleaned);
  };

  const toMapApi = (dimension: string, additional?: AdditionalQP[]) => {
    const filters = {
      dimension,
      account_id: params.licensorId,
      year: params.year,
      channel_ids: params.channel,
      category_ids: params.category,
      page: params.page,
      quarter: params.quarter && params.quarter.charAt(1) || null,
      state: params.state,
      retailer_ids: params.retailer,
      vendor_id: params.vendor,

    };
    if (additional) {
      additional.forEach((a) => {
        filters[a.label] = a.value;
      });
    }
    if (dimension === 'download') {
      filters['client_id'] = params.licensorId;
    }
    const cleaned = omitBy(filters, isNil);
    return querystring.stringify(cleaned);
  };

  const toFullMap = () => {
    const filters = {
      withSales: true,
      withAudience: false,
      accountId: params.licensorId,
      year: params.year,
      channelIds: params.channel,
      categoryIds: params.category,
      quarter: params.quarter && params.quarter.charAt(1) || null,
      state: params.state,
      retailerIds: params.retailer,
      vendorId: params.vendor,
    };
    return omitBy(filters, isNil);

  };

  const toFullRetailerMap = () => {
    const filters = {
      withSales: false,
      withAudience: false,
      withRetailers: true,
      accountId: params.licensorId,
      year: params.year,
      channelIds: params.channel,
      categoryIds: params.category,
      quarter: params.quarter && params.quarter.charAt(1) || null,
      state: params.state,
      retailerIds: params.retailer,
      vendorId: params.vendor,
    };
    return omitBy(filters, isNil);

  };

  const toQueryString = (additional?: AdditionalQP[]) => {
    const filters = {
      page: params.page > 1 ? params.page : null,
      category: params.category,
      channel: params.channel,
      quarter: params.quarter,
      state: params.state,
      vendor: params.vendor,
      retailer: params.retailer,
      year: params.year && params.year !== year ? formattedFiscalYear(params.year) : null,
    };
    if (additional) {
      additional.forEach((a) => {
        filters[a.label] = a.value;
        if (a.label === 'year') {
          if (Number(getYear(a.value)) === year) {
            filters['year'] = null;
          }
        }
      });
    }
    const cleaned = omitBy(filters, isNil);
    return querystring.stringify(cleaned);
  };

  const createLink = (componentPath: string) => {
    const licensorRoute = params.licensorId ? `/clients/${params.licensorId}` : '';
    return `${licensorRoute}/performance/${componentPath}?${toQueryString()}`;
  };

  const isFiltered = () => {
    return toQueryString().length ? true : false;
  };

  const isVendorFiltered = () => {
    return params.vendor ? true : false;
  };

  const scrollToTop = () => {
    document.getElementsByClassName('secondary-gateway-navigation-content')[0].scrollTo(0, 0);
  };

  return { params, toApi, toMapApi, toFullMap, toFullRetailerMap, toQueryString, createLink, isFiltered, isVendorFiltered, scrollToTop };
};
