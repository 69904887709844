import Axios from 'axios';
import * as querystring from 'query-string';
import * as React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { usePerformanceQueryParams } from '.';
import { FullContent } from '../ContentFrame';
import { PaginationFooter } from '../shared';
import { HiddenGeoDataModule, HiddenSalesDataModule } from './HiddenPerformanceModule';
import { PerformanceTitleFilters } from './PerformanceTitleFilters';
import { CategoryPerformance, ChannelPerformance, GeolocationPerformance, PerformanceStats, RetailerPerformance, VendorPerformance } from './submodules';
import { apiToPerformanceStats, DefaultPerformanceStats, VendorRetailerStats } from './submodules/shared';

export const LicenseesPerformancePage = () => {
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [stats, setStats] = React.useState<PerformanceStats>(DefaultPerformanceStats);
  const [loading, setLoading] = React.useState(true);
  const location = useLocation();
  const query = querystring.parse(location.search);
  const performanceQP = usePerformanceQueryParams();
  const isFiltered = query['vendor'] ? true : false;
  React.useEffect(() => {
    getVendors();
    performanceQP.scrollToTop();
  },              [page]);

  React.useEffect(() => {
    if (isFiltered) {
      getData();
    } else {
      getVendors();
    }
    performanceQP.scrollToTop();

  },              [location.search]);

  const getData = async () => {
    setLoading(true);
    const queryParams = performanceQP.toApi('licensee');
    const analytics = await Axios.get(`/api/performance-reports/sales-data?${queryParams}`);
    const s = apiToPerformanceStats(analytics.data.data);
    setStats(s);
    setLoading(false);

  };

  const getVendors = async () => {
    setLoading(true);
    const queryParams = performanceQP.toApi('licensee', [{ value: page, label: 'page' }]);
    const analytics = await Axios.get(`/api/performance-reports/vendors?${queryParams}`);
    const vendors: VendorRetailerStats[] = analytics.data.data.vendors.map((v: any) => {
      return {
        name: v.title,
        id: v.id,
        image: v.image,
        amount: Number(v.amount),
        previousAmount: v.previous ? Number(v.previous.amount) : 0,
        percent: v.percent * 100,
        rpu: Number(v.rpu),
        units: Number(v.quantity),
        sales: Number(v.gross_sales),
      };
    });
    const s = stats;
    s.vendors = vendors;
    s.isCurrent = analytics.data.data.is_current;
    setStats(s);

    setTotalPages(analytics.data.data.totalPages);

    setLoading(false);

  };

  const changePage = (p: number) => {
    setLoading(true);
    setPage(p);
  };

  let additionalGraphs;
  if (isFiltered) {
    additionalGraphs = (<div>

      {stats.salesDataDisplayable ? <div>

        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-portal">
              <div className="panel-body">
                <CategoryPerformance
                  categories={stats.categories}
                  loading={loading}
                  large={false}
                  isAggregate={stats.isAggregate}
                />
              </div>

            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-portal">
              <div className="panel-body">
                <ChannelPerformance
                  channels={stats.channels}
                  loading={loading}
                  large={false}
                  isAggregate={stats.isAggregate}
                />
              </div>
            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-portal">
              <div className="panel-body">
                <RetailerPerformance
                  retailers={stats.retailers}
                  loading={loading}
                  large={false}
                  isAggregate={stats.isAggregate}
                />
              </div>
            </div>

          </div>
        </div>

      </div> :
      <div className="panel panel-portal">
        <div className="panel-body">
          <HiddenSalesDataModule />
        </div>
      </div>
      }
      {stats.geoDisplayable ? <div>
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-portal">
              <div className="panel-body">
                <GeolocationPerformance
                  large={false}
                  loading={loading}
                  states={stats.states}
                  cities={stats.cities}
                  section="map"
                  isAggregate={stats.isAggregate}
                />

              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-portal">
              <div className="panel-body">
                <GeolocationPerformance
                  large={false}
                  loading={loading}
                  states={stats.states}
                  cities={stats.cities}
                  section="states"
                  isAggregate={stats.isAggregate}
                />

              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-portal">
              <div className="panel-body">
                <GeolocationPerformance
                  large={false}
                  loading={loading}
                  states={stats.states}
                  cities={stats.cities}
                  section="cities"
                  isAggregate={stats.isAggregate}
                />

              </div>
            </div>
          </div>
        </div>
      </div> :
        <div className="panel panel-portal">
          <div className="panel-body">
            <HiddenGeoDataModule />
          </div>
        </div>
      }
      {stats.salesDataDisplayable ? <div className="row">
        <div className="col-md-12">
          <div className="panel panel-portal">
            <div className="panel-body" style={{ textAlign: 'center' }}>
              <h4>Royalty Report Data Export</h4>
              <p className="text-muted">Export royalty report data for this vendor.</p>
              <Link target="_blank" to={`/api/royalty-analytics/vendors/${performanceQP.params.vendor}/downloads?${performanceQP.toApi('download')}`}>
                <button className="btn btn-default">
                  <i className="fa fa-download" /> Download Export
                  </button>
              </Link>
            </div>
          </div>
        </div>

      </div> : null}

    </div>);

  }
  return (
    <FullContent>
      <PerformanceTitleFilters
        title="Licensees"
        subTitle="Sales by reported licensee."
        isAggregate={stats.isAggregate}
        isCurrent={stats.isCurrent}
      />
      <div className="panel panel-portal">
        <div className="panel-body">
          <VendorPerformance
            vendors={stats.vendors}
            loading={loading}
            large={true}
            isAggregate={stats.isAggregate}
            page={page}
          />
        </div>
      </div>
      {!isFiltered ? <PaginationFooter hasNext={totalPages > page} currentPage={page} setPage={changePage} /> : null}
      {additionalGraphs}
    </FullContent>
  );

};
