import Axios, { CancelTokenSource } from 'axios';
import { LatLng } from 'leaflet';
import * as moment from 'moment';
import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { RouteComponentProps } from 'react-router';
import { UserContext } from '../../../contexts';
import { AffinityClient, BrandMarkAsset, ClientRule, Design, DesignActivity, DesignPhase, ProductCategory } from '../../../shared';
import { Insignia } from '../../../shared/Insignia';
import { getAffinityPageTitle } from '../../../utils';
import { ContentColumnSizes, ContentWithSidebar, FullContent } from '../../ContentFrame';
import { fetchDesignResponses } from '../../DesignResponses/api';
import { DesignResponse } from '../../DesignResponses/DesignResponse';
import { KeyboardShortcuts, LoadingSpinner } from '../../shared';
import { deleteDesign, DesignUpdateParams, expediteDesign, getClients, getDesign, getDesignLicenses, getDesignNavigation, getLicensorDetails, reviewDesign, submitComment, submitDesignIteration, updateDesignDetails, uploadDesignFile } from '../api';
import { DesignFilters } from '../DesignFilters';
import { CategoryAlert, LicensedAlert } from './DesignAlert';
import { DesignLicensorInformation } from './DesignLicensorInformation';
import { DesignSidebar } from './DesignSidebar';
import { Iteration } from './Iteration';
import { LicensorInsignia } from './LicensorInsignia';
import { ReviewersTab } from './ReviewersTab';
import { RoyaltiesTab } from './RoyaltiesTab';

interface IState {
  loading: boolean;
  design: Design;
  availableCategories: any[];
  availableLicensors: AffinityClient[];
  filters: DesignFilters;
  canPin: boolean;
  pin: LatLng | null;
  shownActivity: DesignActivity | null;
  licensorInfo: LicensorInfo[];
  loadingLicenses: boolean;
  designLicenses: DesignLicense[];
  pendingDesignLicenses: any[];
  designResponses: DesignResponse[];
  submittingIteration: boolean;
  nextDesignId: number | null;
  nextPage: number | null;
  previousDesignId: number | null;
  previousPage: number | null;
  loadingNavigation: boolean;
  savingComment: boolean;
  savingDesign: boolean;
  error: string;
  deletingDesign: boolean;
  activeTab: number;
}

interface LicensorInfo {
  licensor: AffinityClient;
  branding: BrandMarkAsset[];
  insignia: Insignia[];
  designTerms: { text: string; title: string; type: string; }[];
  rules: ClientRule[];
}

interface DesignLicense {
  category: number;
  licensor: number;
  licenseStatus: {
    id: number,
    display: string;
  };
}

export class DesignPage extends React.Component<RouteComponentProps<any>, IState> implements KeyboardShortcuts {
  static contextType = UserContext;

  private _getDesignSource: CancelTokenSource;
  private _getLicensorDetailsSource: CancelTokenSource;
  private _updateDesign: CancelTokenSource;
  private _deleteDesign: CancelTokenSource;
  private _reviewDesign: CancelTokenSource;
  private _expediteDesign: CancelTokenSource;
  private _getDesignLicenses: CancelTokenSource;
  private _getDesignResponse: CancelTokenSource;
  private _createNewIteration: CancelTokenSource;
  private _getDesignNavigation: CancelTokenSource;

  constructor(props: RouteComponentProps<any>, context: any) {
    super(props);

    const filters = new DesignFilters(props.match, this.props.location.search, context.type);
    filters.setQueryParamsFromLocation(this.props.location.search);
    if (this.props.location.search['queue']) {
      filters.queue = Number(this.props.location.search['queue']);
    }
    const design = new Design();
    design.id = this.designID;
    this.state = {
      filters,
      design,
      loading: true,
      pin: null,
      canPin: false,
      shownActivity: null,
      availableCategories: [],
      availableLicensors: [],
      licensorInfo: [],
      loadingLicenses:  false,
      designLicenses: [],
      pendingDesignLicenses: [],
      designResponses: [],
      submittingIteration: false,
      nextDesignId: null,
      nextPage: null,
      previousDesignId: null,
      previousPage: null,
      loadingNavigation: false,
      savingComment: false,
      savingDesign: false,
      error: '',
      deletingDesign: false,
      activeTab: 1,
    };
    this.getDesign = this.getDesign.bind(this);
    this.createComment = this.createComment.bind(this);
    this.pinAdded = this.pinAdded.bind(this);
    this.selectPin = this.selectPin.bind(this);
    this.selectedPinShown = this.selectedPinShown.bind(this);
    this.updateDesign = this.updateDesign.bind(this);
    this.getAvailableLicensors = this.getAvailableLicensors.bind(this);
    this.getLicensorDetails = this.getLicensorDetails.bind(this);
    this.primaryInsignia = this.primaryInsignia.bind(this);
    this.reviewDesign = this.reviewDesign.bind(this);
    this.deleteDesign = this.deleteDesign.bind(this);
    this.expediteDesign = this.expediteDesign.bind(this);
    this.getDesignLicenses = this.getDesignLicenses.bind(this);
    this.isLicensed = this.isLicensed.bind(this);
    this.getDesignResponse = this.getDesignResponse.bind(this);
    this.submitNewIteration = this.submitNewIteration.bind(this);
    this.setCanPin = this.setCanPin.bind(this);
    this.keyboardShortcutHandler = this.keyboardShortcutHandler.bind(this);
    this.getDesignNavigation = this.getDesignNavigation.bind(this);

  }

  componentDidMount() {
    this.getDesign(this.state.filters);
    this.getDesignNavigation(this.state.filters);
    this.getAvailableLicensors();
    document.addEventListener('keydown', this.keyboardShortcutHandler, false);
  }

  componentWillUnmount() {

    document.removeEventListener('keydown', this.keyboardShortcutHandler, false);
    if (this._getDesignSource) {
      this._getDesignSource.cancel('Cancelled getDesign() XHR due to unmount.');
    }
    if (this._getLicensorDetailsSource) {
      this._getLicensorDetailsSource.cancel('Cancelled getLicensorDetails() XHR due to unmount.');
    }
    if (this._updateDesign) {
      this._updateDesign.cancel('Cancelled updateDesign() XHR due to unmount.');
    }
    if (this._deleteDesign) {
      this._deleteDesign.cancel('Cancelled deleteDesign() XHR due to unmount.');
    }
    if (this._reviewDesign) {
      this._reviewDesign.cancel('Cancelled reviewDesign() XHR due to unmount.');
    }
    if (this._expediteDesign) {
      this._expediteDesign.cancel('Cancelled expediteDesign() XHR due to unmount.');
    }
    if (this._getDesignNavigation) {
      this._getDesignNavigation.cancel('Cancelled getDesignNavigation() XHR due to unmount.');
    }
  }
  componentDidUpdate(prevProps: any, prevState: IState) {
    if (prevProps.match.params['id'] !== this.designID) {
      const filters = this.state.filters;
      filters.setQueryParamsFromLocation(this.props.location.search);
      if (this.props.location.search['queue']) {
        filters.queue = Number(this.props.location.search['queue']);
      }
      this.setState({ filters, error: '', canPin: false, pin: null, activeTab: 1 });
      this.getDesign(filters);
      this.getDesignNavigation(filters);
    }
  }

  keyboardShortcutHandler(event: KeyboardEvent) {

    const target = event.target as Element;
    const ignoreKey = target.localName === 'input' ||
      target.localName === 'select' ||
      target.localName === 'textarea' ||
      target.className === 'ql-editor' ||
      this.state.savingComment ||
      this.state.savingDesign;

    if (!ignoreKey) {
      let nextQp = this.state.filters.generateQueryParamString();
      let prevQp = this.state.filters.generateQueryParamString();

      if (this.state.nextPage) {
        nextQp = this.state.filters.generateQueryParamString({ page: this.state.nextPage });
      }
      if (this.state.previousPage) {
        prevQp = this.state.filters.generateQueryParamString({ page: this.state.previousPage });
      }
      if (this.state.filters.licensorId) {
        nextQp += `&licensor=${this.state.filters.licensorId}`;
        prevQp += `&licensor=${this.state.filters.licensorId}`;
      }

      if (event.key === 'ArrowLeft') {
        if (this.state.previousDesignId) {
          this.props.history.push(`/designs/${this.state.previousDesignId}?${prevQp}`);
        }

      }
      if (event.key === 'ArrowRight') {
        if (this.state.nextDesignId) {
          this.props.history.push(`/designs/${this.state.nextDesignId}?${nextQp}`);
        }
      }
      if (event.key === 'Escape') {
        this.props.history.push(this.backNavigation);
      }

    }

  }

  pinAdded(pin: LatLng) {
    this.setState({ pin });
  }

  setCanPin(canPin: boolean) {
    this.setState({ canPin });
  }

  selectPin(activity: DesignActivity) {
    this.setState({ shownActivity: activity });
  }

  selectedPinShown() {
    this.setState({ shownActivity: null });
  }

  primaryInsignia() {
    const primary = this.state.licensorInfo
      .find(info => this.state.design.primaryClient && info.licensor.id === this.state.design.primaryClient.id);
    if (primary) {
      return primary.insignia;
    }
    return [];
  }

  isLicensed(licensor: number): LicensedAlert {
    const alert: LicensedAlert = {
      licensed: false,
      hasLicenses: false,
      pending: false,
      pendingLicenses: this.state.pendingDesignLicenses,
    };

    const licenses = this.state.designLicenses.filter(license => license.licensor === licensor);
    if (licenses.length) {
      const hasAnyApproved = licenses.reduce(
        (prev, current) => {
          if (Number(current.licenseStatus.id) === 2) {
            return true;
          }
          return prev;
        },
        false);
      alert.hasLicenses = hasAnyApproved;
    }

    const a = this.state.design.productCategories.map((c) => {
      const al: LicensedAlert = {
        licensed: false,
        hasLicenses: false,
        pending: false,
        pendingLicenses: [],
      };
      const license = licenses.find(l => l.category === Number(c.id));
      if (license) {
        if (license.licenseStatus.id === 1) {
          al.pending = true;
        }
        if (license.licenseStatus.id === 2) {
          al.licensed = true;
        }
      }
      // Marketing Materials
      if (Number(c.id) === 1216) {
        if (licenses.length) {
          al.licensed = true;
          al.pending = false;
        }
      }
      return al;
    });

    const aPending = a.map(l => l.pending)
      .reduce((p, n) => {
        if (!n) {
          return false;
        }
        return p;

      },      true);
    const aLicensed = a.map(l => l.licensed)
      .reduce((p, n) => {
        if (!n) {
          return false;
        }
        return p;
      },      true);
    alert.pending = aPending;
    alert.licensed = aLicensed;

    return alert;
  }

  isCategoryAllowed(licensor: number): CategoryAlert[] {
    const info = this.state.licensorInfo.find(i => i.licensor.id === licensor);
    return this.state.design.productCategories.map((c) => {
      let allowed = true;
      let partial = false;
      let r = null;
      if (info) {
        const rule = info.rules.find(r => r.productCategoryId === Number(c.id));
        if (rule) {
          r = rule;
          allowed = false;
          const excluded = rule.excludedVendors.indexOf(this.state.design.vendor.id);
          if (excluded === -1) {
            if (rule.distributionChannelId) {
              partial = true;
            } else {
              partial = false;
            }
          } else {
            allowed = true;
            if (rule.brandMarkId !== null || rule.distributionChannelId !== null) {
              partial = true;
            }
          }
        }
      }
      return { allowed, partial, rule: r, category: c };
    });

  }

  render() {
    const design = this.state.design;
    let body;
    let iterationBody;
    if (this.state.loading || this.state.submittingIteration) {
      body = (<LoadingSpinner />);

    } else {
      const otherIterations = design.otherIterations.reverse().map((iteration, index) => (
        <Iteration
          key={index}
          canAddPin={false}
          isSecondary={true}
          iteration={iteration}
          spot={(design.otherIterations.length - index)}
          design={design}
        />
      ));
      iterationBody = otherIterations;
      const information = this.state.licensorInfo.map(info => (
        <DesignLicensorInformation
          key={info.licensor.id}
          categories={[]}
          categoryAlert={{ licensor: info.licensor, alerts: this.isCategoryAllowed(info.licensor.id) }}
          licensedAlert={this.isLicensed(info.licensor.id)}
          terms={info.designTerms}
          licensor={info.licensor}
          loadingLicenses={this.state.loadingLicenses}
        />

      ));
      const insignias = this.state.licensorInfo.map(info => (
        <LicensorInsignia key={info.licensor.id} licensor={info.licensor} brandMarks={info.branding} />
      ));
      const primaryIteration = design.primaryIteration ?  (
        <Iteration
          isSecondary={false}
          canAddPin={this.state.canPin}
          pinAdded={this.pinAdded}
          iteration={design.primaryIteration}
          spot={design.iterations.length}
          selectedActivity={this.state.shownActivity}
          design={design}
        />
      ) : <div>
        <h3 className="text-center">Missing Primary Iteration</h3>
      </div>;

      const tabs = (
        <UserContext.Consumer>
          {user => user.type !== 'vendor' ? (
            <Tabs activeKey={this.state.activeTab} onSelect={k => this.setState({ activeTab: k })} id="designs-tabs">
              <Tab eventKey={1} title="Design">
                {primaryIteration}

              </Tab>
              {user.type === 'admin' ? <Tab eventKey={2} title="Licensor Information">
                {information}
              </Tab> : undefined}
              {user.type === 'admin' ? <Tab eventKey={3} title="Licensor Insignia">
                {insignias}
              </Tab> : undefined}
              {user.type === 'admin' ? <Tab eventKey={4} title="Users">
                <ReviewersTab />

              </Tab> : undefined}
              <Tab eventKey={5} title="Royalties">
                {this.state.activeTab === 5 ? <RoyaltiesTab onDesignsPage={true} /> : null}
              </Tab>

            </Tabs>
          ) : primaryIteration}

        </UserContext.Consumer>
      );
      body = (
        <div className="panel panel-portal">
          <div className="panel-body">
            {tabs}
          </div>

        </div>

      );
    }

    let sidebar;
    if (this.state.loading) {
      sidebar = (
        <div className="panel panel-portal">
          <LoadingSpinner />
        </div>
      );
    } else {
      const licensors: AffinityClient[] = [];
      if (design.primaryClient) {
        licensors.push(design.primaryClient);
      }
      if (design.secondayClients.length) {
        design.secondayClients.forEach(licensor => licensors.push(licensor));
      }

      const licensed: LicensedAlert = licensors
        .map(licensed => this.isLicensed(licensed.id))
        .reduce((prev, next) => {
          const temp = prev;
          if (next.pending) {
            temp.pending = true;
          }
          if (!next.hasLicenses) {
            temp.hasLicenses = false;
          }
          if (!next.licensed) {
            temp.licensed = false;
          }
          return temp;

        },      { licensed: true, pending: false, hasLicenses: true, pendingLicenses: this.state.pendingDesignLicenses });

      const categoryAllowed = licensors
        .map((licensed) => {
          return {
            licensor: licensed,
            alerts: this.isCategoryAllowed(licensed.id),
          };
        });

      sidebar = (
        <DesignSidebar
          createComment={this.createComment}
          savingComment={this.state.savingComment}
          availableLicensors={this.state.availableLicensors}
          availableCategories={this.state.availableCategories}
          availableInsignia={this.primaryInsignia()}
          design={design}
          updateDesign={this.updateDesign}
          reviewDesign={this.reviewDesign}
          expediteDesign={this.expediteDesign}
          licensedAlert={licensed}
          categoryAlert={categoryAllowed}
          loadingLicenses={this.state.loadingLicenses}
          responses={this.state.designResponses}
          submitNewIteration={this.submitNewIteration}
          submittingIteration={this.state.submittingIteration}
          nextDesignId={this.state.nextDesignId}
          previousDesignId={this.state.previousDesignId}
          nextPage={this.state.nextPage}
          previousPage={this.state.previousPage}
          filters={this.state.filters}
          selectPin={this.selectPin}
          setCanPin={this.setCanPin}
          savingDesign={this.state.savingDesign}
          errorMessage={this.state.error}
          loadingNavigation={this.state.loadingNavigation}
          backRoute={this.backNavigation}
          deleteDesign={this.deleteDesign}
          deletingDesign={this.state.deletingDesign}
        />
      );
    }

    const columnSizes: ContentColumnSizes = {
      main: 'col-xl-9 col-lg-8 col-md-7',
      sidebar: 'col-xl-3 col-lg-4 col-md-5',
    };

    return (
      <DocumentTitle title={getAffinityPageTitle('Designs')}>
        <div className="content-padding-frame">
          <div className="col-md-12">
            <ContentWithSidebar
              columnSizes={columnSizes}
              main={body}
              sidebar={sidebar}
            />
          </div>
          <div style={{ padding: 0 }} className="col-md-12">
            <FullContent>
              {iterationBody}
            </FullContent>
          </div>
        </div>

      </DocumentTitle>

    );

  }
  getDesign(filters: DesignFilters) {
    this.setState({ loading: true });
    if (this._getDesignSource) {
      this._getDesignSource.cancel('Cancelled getDesign() XHR due to new request.');
    }
    this._getDesignSource = Axios.CancelToken.source();
    getDesign(this.designID, filters.generateAPIParams(), this._getDesignSource)
      .then((response) => {
        const design = Design.fromApi(response.data.data);
        const availableCategories = response.data.data.products.map((product: any) => product.category);
        if (design.primaryClient) {
          this.getLicensorDetails(design);
          this.getDesignLicenses(design);
        }
        this.getDesignResponse(design);
        this.setState({ design, availableCategories, loading: false });
      });
  }

  getAvailableLicensors() {
    getClients()
      .then((response) => {
        this.setState({ availableLicensors: response });
      });
  }

  getLicensorDetails(design: Design) {
    const licensors: AffinityClient[] = [];
    if (design.primaryClient) {
      licensors.push(design.primaryClient);
    }
    if (design.secondayClients.length) {
      design.secondayClients.forEach(licensor => licensors.push(licensor));
    }
    if (this._getLicensorDetailsSource) {
      this._getLicensorDetailsSource.cancel('Canceled getLicensorDetails() XHR due to new request');
    }
    this._getLicensorDetailsSource = Axios.CancelToken.source();
    getLicensorDetails(licensors, this._getLicensorDetailsSource)
      .then((response) => {
        if (response.data && response.data.data) {
          const info: LicensorInfo[] = response.data.data.map((info: any) => {
            const details: LicensorInfo = {
              licensor: new AffinityClient({ id: info.id, short_name: info.name }),
              branding: info.branding.map((brand: any) => new BrandMarkAsset(brand)),
              designTerms: info.design_terms,
              rules: info.client_rules.map((rule: any) => ClientRule.fromApi(rule)),
              insignia: Insignia.listFromApi(info.insignia),
            };

            return details;
          });
          this.setState({ licensorInfo: info });
        }

      });
  }

  getDesignLicenses(design: Design) {
    this.setState({ loadingLicenses: true });
    const licensors: AffinityClient[] = [];
    if (design.primaryClient) {
      licensors.push(design.primaryClient);
    }
    if (design.secondayClients.length) {
      design.secondayClients.forEach(licensor => licensors.push(licensor));
    }
    if (this._getDesignLicenses) {
      this._getDesignLicenses.cancel('Cancelled getDesignLicenses() XHR due to new request');
    }
    this._getDesignLicenses = Axios.CancelToken.source();
    getDesignLicenses(design.vendor, licensors, this._getDesignLicenses)
      .then((response) => {
        const designLicenses = response.data.data.current.map((l: any) => {
          const license: DesignLicense = {
            category: Number(l.category_id),
            licensor: Number(l.client_id),
            licenseStatus: l.license_status,
          };
          license.licenseStatus.id = Number(license.licenseStatus.id);
          return license;
        });
        this.setState({ designLicenses, pendingDesignLicenses: response.data.data.pending, loadingLicenses: false });
      });
  }

  createComment(comment: string, group: number, attachedFiles: File[]) {
    this.setState({ savingComment: true });
    let call;
    if (this.state.pin) {
      call = submitComment(this.designID, comment, group, attachedFiles, this.state.pin);
    } else {
      call = submitComment(this.designID, comment, group, attachedFiles);
    }
    call
      .then((response) => {
        const activity = DesignActivity.fromApi(response.data.data);
        const design = this.state.design;
        design.primaryIteration.activity = [activity, ... design.primaryIteration.activity];
        this.setState({ design, savingComment: false, error: '' });
      }).catch((error) => {
        this.setState({ savingComment: false, error: 'There was an error saving your comment.' });
      });
  }

  updateDesign(design: Design, update: boolean) {
    this.setState({ design, savingDesign: update });
    if (update) {
      const params = {
        title: design.title,
        primaryClient: design.primaryClient && design.primaryClient.id || null,
        productCategories: design.productCategories.map(c => c.id),
        insignia: design.insignia.map(i => i.id),
        secondaryClients: design.secondayClients.map(licensor => licensor.id),
        channel_retail: design.channelRetail,
        channel_direct: design.channelDirect,
        channel_internal: design.channelInternal,
        customer: design.customer,
        unit_estimation: design.unitEstimation,
        university_funds: design.universityFunds,
        not_for_resale: design.notForResale,
        qualifies_for_exemption: design.qualifiesForExemption,
      };
      if (this._updateDesign) {
        this._updateDesign.cancel('Cancelled updateDesign() XHR due to new request.');
      }
      this._updateDesign = Axios.CancelToken.source();
      updateDesignDetails(design.id, params, this._updateDesign)
        .then((response) => {
          const design = Design.fromApi(response.data.data);
          this.setState({ design, savingDesign: false, error: '' });
        }).catch((error) => {
          this.setState({ savingDesign: false, error: 'There was an error updating this design.' });
        });
      this.getLicensorDetails(design);
      this.getDesignLicenses(design);
    }

  }

  deleteDesign() {
    if (this._deleteDesign) {
      this._deleteDesign.cancel('Cancelled deleteDesign() XHR due to new request');
    }
    this._deleteDesign = Axios.CancelToken.source();
    this.setState({ deletingDesign: true });
    deleteDesign(this.designID)
      .then((response) => {
        this.props.history.push(this.backNavigation);
      });
  }

  expediteDesign(shouldExpedite: boolean) {
    if (this._expediteDesign) {
      this._expediteDesign.cancel('Cancelled expediteDesign() XHR due to new request');
    }
    this.setState({ savingDesign: true });
    this._expediteDesign = Axios.CancelToken.source();
    expediteDesign(this.designID, shouldExpedite, this._expediteDesign)
      .then((response) => {
        const design = this.state.design;
        design.expedited = moment();
        this.setState({ design, savingDesign: false });
      })
      .catch(error => this.setState({ savingDesign: false }));
  }

  reviewDesign(phase: DesignPhase, comment: string, notify: boolean, advance: boolean, attachedFiles: File[]) {
    this.setState({ savingDesign: true });
    if (this._reviewDesign) {
      this._reviewDesign.cancel('Cancelled reviewDesign() XHR due to new request');
    }
    const pin = this.state.pin ? this.state.pin : undefined;
    this._reviewDesign = Axios.CancelToken.source();
    reviewDesign(this.designID, phase, comment, notify, this._reviewDesign, attachedFiles, pin)
      .then((response) => {
        const design = Design.fromApi(response.data.data);
        this.setState({ design, savingDesign: false, error: '' });
        if (advance && this.state.nextDesignId && notify) {
          const qp = this.state.filters.generateQueryParamString();
          this.props.history.push(`/designs/${this.state.nextDesignId}?${qp}`);
        }
      }).catch((error) => {
        this.setState({ savingDesign: false, error: 'There was an error reviewing this design.' });
      });

  }
  getDesignResponse(design: Design) {
    if (this._getDesignResponse) {
      this._getDesignResponse.cancel('Cancelled getDesignResponse() XHR due to new request');
    }
    this._getDesignResponse = Axios.CancelToken.source();
    const account = design.primaryClient ? design.primaryClient.id : null;
    fetchDesignResponses(account, this._getDesignResponse)
      .then((response) => {
        if (response.data) {
          const designResponses = response.data.data.map((r: any) => {
            const dr: DesignResponse = {
              id: r.id,
              title: r.title,
              body: r.body,
              updatedAt: new Date(r.updated_at),
              account: new AffinityClient(r.account),

            };
            return dr;
          });
          this.setState({ designResponses });
        }
      });
  }

  submitNewIteration(file: File, categories: ProductCategory[]) {
    this.setState({ submittingIteration: true });
    if (this._createNewIteration) {
      this._createNewIteration.cancel('Cancelled createNewVersion() XHR due to new request');
    }
    this._createNewIteration = Axios.CancelToken.source();
    uploadDesignFile(file)
      .then((response) => {
        submitDesignIteration(this.designID, response.id, categories, this._createNewIteration)
          .then((iterationResponse) => {
            const design = Design.fromApi(iterationResponse.data.data);
            this.setState({ design, submittingIteration: false });
          });
      });

  }

  getDesignNavigation(filters: DesignFilters) {
    this.setState({
      nextDesignId: null,
      previousDesignId: null,
      nextPage: null,
      previousPage: null,
      loadingNavigation: true,
    });
    if (this._getDesignNavigation) {
      this._getDesignNavigation.cancel('Cancelled getDesignNavigation() XHR due to new request');
    }
    this._getDesignNavigation = Axios.CancelToken.source();
    getDesignNavigation(this.designID, filters.generateAPIParams(), this._getDesignNavigation)
      .then((response) => {
        const nextPage = response.data.next_page ? Number(response.data.next_page) : null;
        const previousPage = response.data.previous_page ? Number(response.data.previous_page) : null;
        this.setState({
          nextPage,
          previousPage,
          nextDesignId: response.data.next,
          previousDesignId: response.data.previous,
          loadingNavigation: false,
        });
      });
  }

  get backNavigation() {
    const indexQp = this.state.filters.generateQueryParamString();

    let route = '/designs';
    if (this.state.filters.vendorId) {
      route = `/vendors/${this.state.filters.vendorId}/designs`;
    }
    if (this.state.filters.licensorId) {
      route = `/clients/${this.state.filters.licensorId}/designs`;
    }
    return `${route}?${indexQp}`;
  }

  get designID() {
    return this.props.match.params['id'];
  }

}
