import Axios from 'axios';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { useRouteMatch } from 'react-router-dom';
import { Design, RoyaltyReportLineItem } from '../../../shared';
import { SalesDataTable } from '../../Royalties/RoyaltyReportSalesData/SalesDataTable';
import { CurrencyLabel, LoadingSpinner, PaginationFooter } from '../../shared';
interface IProps {
  designId?: number;
  clientId?: number;
}
export const RoyaltiesTab = (props: IProps) => {
  const match = useRouteMatch();
  const [salesData, setSalesData] = React.useState<RoyaltyReportLineItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [paginationDetails, setPaginationDetails] = React.useState({
    totalPages: 1,
    totalResults: 0,
  });
  const [counts, setCounts] = React.useState({
    loading: true,
    royalties: 0.00,
    units: 0,
    sales: 0.00,
  });

  React.useEffect(() => {
    getCounts();

  },              []);

  React.useEffect(() => {
    getSalesData();

  },              [page]);

  if (!match) {
    return <></>;
  }

  const getSalesData = async () => {
    setLoading(true);
    const designId = props.designId ? props.designId :  match.params['id'];
    const clientId = props.clientId ? props.clientId :  null;
    const url = clientId ? `/api/designs/${designId}/royalties?page=${page}&client_id=${clientId}` : `/api/designs/${designId}/royalties?page=${page}`;
    const r = await Axios.get(url);
    const l = r.data.data.map((lineItem: any) => new RoyaltyReportLineItem(lineItem));
    const pagination = { totalResults: r.data.meta.pagination.total, totalPages: r.data.meta.pagination.total_pages };
    setPaginationDetails(pagination);
    setSalesData(l);
    setLoading(false);

  };

  const getCounts = async () => {
    const designId = props.designId ? props.designId :  match.params['id'];
    const clientId = props.clientId ? props.clientId :  null;
    const url = clientId ? `/api/designs/${designId}/royalties-counts?client_id=${clientId}` : `/api/designs/${designId}/royalties-counts`;
    const r = await Axios.get(url);
    setCounts({
      loading: false,
      royalties: r.data.data.royalties,
      sales: r.data.data.sales,
      units: r.data.data.units,
    });

  };

  if (loading || counts.loading) {
    return <LoadingSpinner />;
  }
  if (salesData.length === 0 && page === 1) {
    return <h4 className="text-muted text-center">No sales have been reported with a matching UPI for this design.</h4>;
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 20 }}>
        <div className="text-muted" style={{ width: 150, textAlign: 'center', fontWeight: 'bold' }}>Sales</div>
        <div className="text-muted" style={{ width: 150, textAlign: 'center', fontWeight: 'bold' }}>Units</div>
        <div className="text-muted" style={{ width: 200, textAlign: 'center', fontWeight: 'bold' }}>Royalties</div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 }}>
        <div style={{ width: 150, textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}><CurrencyLabel value={counts.sales} /></div>
        <div style={{ width: 150, textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>{counts.units !== 0 ? <NumberFormat displayType="text" value={counts.units} thousandSeparator={true} /> : '-'}</div>
        <div style={{ width: 200, textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}><CurrencyLabel value={counts.royalties} /></div>
      </div>

      <SalesDataTable
        salesData={salesData}
        updateItem={console.log}
        hideDesignPreview={true}
        showCityState={true}
      />
        <PaginationFooter
          setPage={setPage}
          showFirstLast={true}
          totalPages={paginationDetails.totalPages}
          totalResults={paginationDetails.totalResults}
          currentPage={page}
          hasNext={page < paginationDetails.totalPages}
        />

    </div>
  );

};
