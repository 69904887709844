import { faBadgeDollar, faChartPie, faHashtag, faSackDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import mapboxgl from 'mapbox-gl';
import * as React from 'react';
import { Chart } from 'react-google-charts';
import { useHistory, useLocation, useParams } from 'react-router';
import { states } from '../../../shared';
import { LayerGroup, LayerInfo } from '../../Audience';
import { AffinityMap, LoadingSpinner } from '../../shared';
import { ChartFooter } from '../ChartFooter';
import { ChartHeader } from '../ChartHeader';
import { ChartItemList } from '../ChartItemList';
import { CustomBarChart } from '../CustomBarChart';
import { usePerformanceQueryParams } from '../usePerformanceQueryParams';

interface IProps {
  large: boolean;
  link?: string;
  cities: GeoCityStats[];
  states: GeoStateStats[];
  loading: boolean;
  section?: 'states' | 'cities' | 'map' ;
  singleState? : string;
  isAggregate: boolean;
  onDashboard?: boolean;
}

export interface GeoStateStats {
  state: string;
  fullState: string | undefined;
  amount: number;
  percent: number;
  units: number;
  rpu: number;
  sales: number;
}

export interface GeoCityStats {
  city: string;
  amount: number;
  percent: number;
  units: number;
  rpu: number;
  sales: number;
}

export const GeolocationPerformance = (props: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const performanceQP = usePerformanceQueryParams();
  const map = React.useRef<any>(null);
  const [mapData, setMapData] = React.useState<any>({
    sales: null,
    mapDefaults: null,
  });
  const [loadingMap, setLoadingMap] = React.useState(false);
  const [layers, setLayers] = React.useState<LayerInfo[]>([]);
  const countyClickRef = React.useRef<any>(null);
  const stateClickRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (props.section === 'map') {
      getSales();
      if (map.current.map) {
        setStateCenter();

      }
    }

  },              [location.search]);

  const setStateCenter = () => {
    if (performanceQP.params.state) {
      const foundState = states.find(s => s.shortName === performanceQP.params.state);
      if (foundState) {
        map.current.setCenter(foundState.lng, foundState.lat);
        map.current.setZoom(5);
      }
    }  else {
      map.current.setCenter(-98.583333, 39.833333);
      map.current.setZoom(2.75);

    }
  };

  const onMapLoad = () => {
    if (map.current.map) {
      setStateCenter();

    }
  };

  const onStateClick = (e: any) => {
    const stateSalesPopup = new mapboxgl.Popup();
    const description = `<strong>${e.features[0].properties.NAME}</strong>
                                ${e.features[0].properties.net_sales ? `<br>Rank #${e.features[0].properties.rank}, ${e.features[0].properties.percentage < 1 ? '<1' : e.features[0].properties.percentage}% of royalties` : ''}
                                <br>Sales: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(e.features[0].properties.net_sales)}
                                <br>Royalties: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(e.features[0].properties.royalty_sales)}`;
    stateSalesPopup
      .setLngLat(e.lngLat)
      .setHTML(description)
      .addTo(map.current.map);

  };

  const onCountyClick = (e: any) => {
    const countySalesPopup = new mapboxgl.Popup();
    const description = `<strong>${e.features[0].properties.NAMELSAD}, ${e.features[0].properties.STUSPS}</strong>
                                ${e.features[0].properties.net_sales ? `<br>Rank #${e.features[0].properties.rank}, ${e.features[0].properties.percentage < 1 ? '<1' : e.features[0].properties.percentage}% of royalties` : ''}
                                <br>Sales: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(e.features[0].properties.net_sales)}
                                <br>Royalties: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(e.features[0].properties.royalty_sales)}`;
    countySalesPopup
      .setLngLat(e.lngLat)
      .setHTML(description)
      .addTo(map.current.map);

  };

  const setSalesLayer = (sales: any) => {
    layers.forEach((l) => {
      switch (l.name) {
        case 'state-sales':
          map.current.map.off('click', l.layerName, stateClickRef.current);
          stateClickRef.current = null;
          break;
        case 'county-sales':
          map.current.map.off('click', l.layerName, countyClickRef.current);
          countyClickRef.current = null;
          break;
        default:
          break;
      }
    });
    map.current.removeLayers(layers);
    const l: LayerInfo[] = [];
    const stateSalesInfo: LayerInfo = {
      id: 'state-sales-all',
      name: 'state-sales',
      sourceName: 'state-sales-source',
      layerName: 'state-sales-layer',
      isChecked: true,
      minZoom: 0,
      maxZoom: 5,
    };
    l.push(stateSalesInfo);
    map.current.map.addSource(stateSalesInfo.sourceName, {
      type: 'geojson',
      data: sales.state.salesJson,
    });
    addChoroplethLayer(stateSalesInfo, sales.state.max, sales.state.max2);

    stateClickRef.current = onStateClick;
    map.current.map.on('click', stateSalesInfo.layerName,  stateClickRef.current);

    map.current.map.on('mouseenter', stateSalesInfo.layerName, () => {
      map.current.map.getCanvas().style.cursor = 'pointer';
    });
    map.current.map.on('mouseleave', stateSalesInfo.layerName, () => {
      map.current.map.getCanvas().style.cursor = '';
    });

    if (sales.county && sales.county.salesJson) {
      const countySalesInfo: LayerInfo = {
        id: 'county-sales-all',
        name: 'county-sales',
        sourceName: 'county-sales-source',
        layerName: 'county-sales-layer',
        isChecked: true,
        minZoom: 5,
        maxZoom: 23,
      };
      l.push(countySalesInfo);
      map.current.map.addSource(countySalesInfo.sourceName, {
        type: 'geojson',
        data: sales.county.salesJson,
      });
      addChoroplethLayer(countySalesInfo, sales.county.max, sales.county.max2);

      countyClickRef.current = onCountyClick;
      map.current.map.on('click', countySalesInfo.layerName, countyClickRef.current);

      map.current.map.on('mouseenter', countySalesInfo.layerName, () => {
        map.current.map.getCanvas().style.cursor = 'pointer';
      });
      map.current.map.on('mouseleave', countySalesInfo.layerName, () => {
        map.current.map.getCanvas().style.cursor = '';
      });

    }
    setLayers(l);

  };
  const addChoroplethLayer = (info: LayerInfo, max: number, max2: number) => {
    map.current.map.addLayer(
      {
        id: info.layerName,
        source: info.sourceName,
        minzoom: info.minZoom,
        maxzoom: info.maxZoom,
        visibility: 'visible',
        type: 'fill',
        paint: {
          'fill-color': [
            'interpolate',
            ['exponential', 1],
            // ['linear'],
            ['get', 'count'],
            0,
            'hsla(0, 0%, 0%, 0)',
            1,
            'hsl(210,22%,84%)',
            max2,
            'hsl(198,39%,45%)',
            max,
            'hsl(201,56%,34%)',
          ],
          'fill-opacity': 0.75,
          'fill-outline-color': '#FFFFFF',
        },
      },
      'waterway-label',
    );
  };

  if (props.loading && props.section !== 'map') {
    return (<LoadingSpinner />);
  }

  const cityData = props.cities.map((c) => {
    return {
      city: c.city,
      royalties: c.amount,
      percent: c.percent,
      rpu: c.rpu,
      units: c.units,
      sales: c.sales,
    };
  });
  const stateData = props.states.map((s) => {
    return {
      state: s.fullState,
      royalties: s.amount,
      percent: s.percent,
      rpu: s.rpu,
      units: s.units,
      sales: s.sales,
    };
  });

  const getSales = async () => {
    setLoadingMap(true);
    const s = await Axios.get(`/api/audience/map?withSales=1&${performanceQP.toMapApi('geo')}`);
    setMapData({
      sales: s.data.sales,
      mapDefaults: s.data.default,
    });
    setSalesLayer(s.data.sales);
    setLoadingMap(false);

  };

  const handleClick = (index: number) => {
    const state = props.states[index];
    if (state) {
      const queryString = performanceQP.toQueryString([{ value: state.state, label: 'state' }]);
      if (props.onDashboard) {
        history.push(`${location.pathname}/geolocation?${queryString}`);
      } else {
        history.push(`${location.pathname}?${queryString}`);
      }
    }

  };

  if (props.large) {

    switch (props.section) {
      case 'states':
        return (
          <div>
            <ChartItemList
              listVisualStyle="dot"
              items={stateData}
              itemLabels={[
                { state: 'State' },
                { percent: <span><FontAwesomeIcon className="text-muted" icon={faChartPie} /> Percent</span> },
                { units: <span><FontAwesomeIcon className="text-muted" icon={faHashtag} /> Units</span> },
                { sales: <span><FontAwesomeIcon className="text-muted" icon={faBadgeDollar} /> Sales</span> },
                { royalties: <span><FontAwesomeIcon className="text-muted" icon={faSackDollar} /> Royalties</span> },
              ]}
              showLabels={true}
              useShowAll={true}
              onClick={handleClick}
            />
          </div>
        );
      case 'cities':
        return (
          <div>
            <ChartItemList
              listVisualStyle="dot"
              items={cityData}
              itemLabels={[
                { city: '' },
                { percent: <span><FontAwesomeIcon className="text-muted" icon={faChartPie} /> Percent</span> },
                { units: <span><FontAwesomeIcon className="text-muted" icon={faHashtag} /> Units</span> },
                { sales: <span><FontAwesomeIcon className="text-muted" icon={faBadgeDollar} /> Sales</span> },
                { royalties: <span><FontAwesomeIcon className="text-muted" icon={faSackDollar} /> Royalties</span> },
              ]}
            showLabels={true}
              useShowAll={true}
            />
          </div>
        );

      case 'map':
        const newMap = (
          <div>
            <AffinityMap useDefaultCenter={true} fullParams={performanceQP.toFullMap()} isFullScreen={false} ref={map} height={500} width="100%" loading={loadingMap} onMapLoad={onMapLoad} />
          </div>

        );
        return (
          <div>
            {newMap}
          </div>
        );
    }

  }

  if (props.section) {
    switch (props.section) {
      case 'states':
        const stateLabels: any = props.onDashboard ?
          [{ state: '' }, { percent: 'Percent' }] :
        [
            { state: '' },
            { percent: <span><FontAwesomeIcon className="text-muted" icon={faChartPie} /> Percent</span> },
            { units: <span><FontAwesomeIcon className="text-muted" icon={faHashtag} /> Units</span> },
            { sales: <span><FontAwesomeIcon className="text-muted" icon={faBadgeDollar} /> Sales</span> },
            { royalties: <span><FontAwesomeIcon className="text-muted" icon={faSackDollar} /> Royalties</span> },
        ];
        return (
          <div>
            <ChartHeader header="States" subHeader="Sales by state." />

            {stateData.length && !props.onDashboard ?
              <CustomBarChart data={stateData.map(l => l.percent)} id="states" /> : null
            }
            <ChartItemList
              listVisualStyle={props.onDashboard ? 'none' : 'dot'}
              items={stateData.slice(0, stateData.length >= 5 ? 5 : stateData.length)}
              itemLabels={stateLabels}
              showLabels={!props.onDashboard}
              onClick={handleClick}
            />
            {stateData.length < 5 ? null : <ChartFooter isPartial={props.isAggregate} link={performanceQP.createLink('geolocation')} />}
          </div>
        );
      case 'cities':
        const cityLabels: any = props.onDashboard ?
          [{ city: '' }, { percent: 'Percent' }] :
          [{ city: '' }, { percent: 'Percent' }, { units: 'Units' }, { sales: 'Sales' }, { royalties: 'Royalties' }];
        return (
          <div>
            <ChartHeader header="Cities" subHeader="Sales by city." />
            {cityData.length && !props.onDashboard ?
              <CustomBarChart data={cityData.map(l => l.percent)} id="cities" /> : null
            }
            <ChartItemList
              listVisualStyle={props.onDashboard ? 'none' : 'dot'}
              items={cityData.slice(0, cityData.length >= 5 ? 5 : cityData.length)}
              itemLabels={cityLabels}
              showLabels={!props.onDashboard}
            />
            {cityData.length < 5 ? null : <ChartFooter isPartial={props.isAggregate} link={performanceQP.createLink('geolocation')} />}
          </div>
        );

      case 'map':
        return (
        <div>
          <ChartHeader header="Geolocation" subHeader="Sales by geolocation." />
            <AffinityMap useDefaultCenter={true} fullParams={performanceQP.toFullMap()} isFullScreen={false} ref={map} height={300} width="100%" loading={loadingMap} onMapLoad={onMapLoad} />
            {stateData.length < 5 ? null : <ChartFooter isPartial={props.isAggregate} link={performanceQP.createLink('geolocation')} />}
        </div>
        );
    }
  }

  return (
    <div>
      <ChartHeader header="Channels" subHeader="Sales by geolocation." />
      <AffinityMap isFullScreen={false} ref={map} height={300} width="100%" loading={loadingMap} onMapLoad={() => console.log('map loaded')} />

      <div className="row">
        <div className="col-md-6">
          <ChartItemList
            listVisualStyle="dot"
            items={stateData}
            itemLabels={[{ state: 'State' }, { royalties: 'Royalties' }]}
            showLabels={true}
          />
        </div>
        <div className="col-md-6">
          <ChartItemList
            listVisualStyle="dot"
            items={cityData}
            itemLabels={[{ city: 'City' }, { royalties: 'Royalties' }]}
            showLabels={true}
          />
        </div>
      </div>

      <ChartFooter isPartial={props.isAggregate} link={props.link ? props.link : ''} />
    </div>
  );
};
