import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { groupBy, map, orderBy } from 'lodash';
import * as React from 'react';
import { Highlighter, Menu, MenuItem, Token, Typeahead } from 'react-bootstrap-typeahead';
import { AffinityClient, Vendor } from '../../../../shared';
import { Insignia } from '../../../../shared/Insignia';

interface IProps {
  insignia: Insignia[];
  vendor: Vendor;
  primaryLicensor: AffinityClient;
  availableInsignia: Insignia[];
  isReadOnly: boolean;
  onChange: (insignia: Insignia[]) => void;
}

interface IState {
  insignia: Insignia[];
  loading: boolean;
}

export class DesignReviewInsignia extends React.Component<IProps, IState> {

  _typeahead: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      insignia: [],
      loading: true,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(insignia: Insignia[]) {
    this.props.onChange(insignia);
  }

  render() {
    const insignia = this.props.insignia;
    if (this.props.isReadOnly) {
      if (insignia.length) {
        const list = insignia.map(i => i.title).join(', ');
        return (
          <p className="text-center"><strong>Insignia: </strong>{list}</p>
        );
      }
      return null;
    }

    return (
      <div className="form-group">
        <label className="small">Insignia</label>
        <InsigniaTypeahead
          vendor={this.props.vendor}
          licensor={this.props.primaryLicensor}
          onChange={this.props.onChange}
          selected={this.props.insignia}
        />

      </div>
    );
  }

}

interface InsigniaTypeaheadProps {
  vendor: Vendor;
  onChange: (insignia: Insignia[]) => void;
  licensor: AffinityClient;
  selected: Insignia[];
}

const InsigniaTypeahead = (props: InsigniaTypeaheadProps) => {
  const [insignia, setInsignia] = React.useState<Insignia[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getInsignia();

  },              [props.licensor.id]);

  const getInsignia = async () => {
    setLoading(true);
    const ins = await Axios.get(`/api/brandmarks?hide_deleted=1&include_rules=1&client_id=${props.licensor.id}`);
    setInsignia(ins.data.data.map((i: any) => Insignia.fromApi(i)));
    setLoading(false);

  };

  const parentHasRules: any = (ins: Insignia) => {
    // find parent with rule information
    const p = insignia.find((i) => {
      if (ins.parent) {
        return i.id === ins.parent.id;
      }
      return false;
    });
    if (p) {
      return ins.hasRules() || parentHasRules(p);
    }
    return ins.hasRules();
  };

  const renderToken = (selected: Insignia, tokenProps: any, index: number) => {

    return (
      <Token
        key={index}
        onRemove={tokenProps && tokenProps.onRemove}
        style={loading && !insignia.length ? undefined : {
          // backgroundColor: '#f2f8fc',
          backgroundColor: selected.isVendorAllowed(props.vendor) ? !parentHasRules(selected) ? '#dff0d8' : '#fcf8e3' : '#f2dede',
          color: selected.isVendorAllowed(props.vendor) ? !parentHasRules(selected) ? '#3c763d' : '#8a6d3b' : '#a94442',
        }}
      >
       {loading && !insignia.length ? 'Loading...' : selected.title}

      </Token>
    );

  };
  const renderMenu = (insignia: Insignia[], menuProps: any) => {
    let index = 0;
    // @ts-ignore
    const orderedInsignia = orderBy(insignia.filter(i => !props.selected.map(ins => ins.id).includes(i.id)), 'order', 'asc');
    const grouped = groupBy(orderedInsignia
      .filter(i => i.parent)
      .filter(i => i.enabledForDesigns),
                            i => i.parent && i.parent.title);
    const ungrouped = orderedInsignia.filter(i => !i.parent && i.enabledForDesigns);

    const getMenuItem = (insignia: Insignia) => {
      const position = insignia.order ? insignia.order : 999;
      return <MenuItem
        key={insignia.id}
        option={insignia}
        position={position}
      >
        <Highlighter>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 25, height: 25, marginRight: 10 }}>
              {
                insignia.image.isUsingDefault ? null : (
                  <img className="img-responsive" alt="" src={insignia.image.getSize('th')} />
              )}
            </div>
            <span style={{ fontSize: 8, marginRight: 5 }}> <FontAwesomeIcon  icon={faCircle} className={insignia.isVendorAllowed(props.vendor) ? !parentHasRules(insignia) ? 'text-success' : 'text-warning' : 'text-danger'}  /> </span>
            <span>{insignia.title} </span>
          </div>
        </Highlighter>
      </MenuItem>;
    };

    const groupedItems = Object.keys(grouped).map(parent => [
      !!index && <Menu.Divider key={`${parent}-divider`} />,
      <Menu.Header key={`${parent}-header`}>
        {parent}
      </Menu.Header>,
      map(grouped[parent], (state) => {
        const item = getMenuItem(state);

        index++;
        return item;
      }),
    ]);
    const ungroupedItems = ungrouped.length > 0 ? (
      <>
      <Menu.Header key="ungrouped-header">Top-level</Menu.Header>
        {ungrouped.map((insignia: Insignia) => (
          getMenuItem(insignia)
        ))}
        <Menu.Divider key="ungrouped-divider" />
      </>
    ) : null;

    return (
      <Menu {...menuProps}>
        {ungroupedItems}
        {groupedItems}
      </Menu>
    );

  };

  return (
    <Typeahead
      id="insignia-typeahead"
      placeholder="Insignia"
      renderMenu={renderMenu}
      selected={props.selected}
      options={insignia}
      onChange={props.onChange}
      multiple={true}
      labelKey={option => option.title}
      renderToken={renderToken}
      paginate={false}
      isLoading={loading}
      maxResults={insignia.length}

    />
  );

};
