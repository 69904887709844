import Axios from 'axios';
import * as React from 'react';
import { useLocation } from 'react-router';
import { FullContent } from '../ContentFrame';
import { PerformanceTitleFilters } from './PerformanceTitleFilters';
import { InsigniaGraph, InsigniaStats } from './submodules';
import { usePerformanceQueryParams } from './usePerformanceQueryParams';

export const PerformanceDesignInsigniaPage = () => {
  const [loading, setLoading] = React.useState(true);
  const [insingia, setInsignia] = React.useState<InsigniaStats[]>([]);
  const location = useLocation();
  const params = usePerformanceQueryParams();

  React.useEffect(() => {
    getData();

  },              [location.search]);

  const getData = async () => {
    setLoading(true);
    setInsignia([]);
    const qs = params.toApi('');
    const insig = await Axios.get(`/api/performance-reports/insignia?${qs}`);
    setInsignia(insig.data.data);
    setLoading(false);
  };

  return (
    <FullContent>
      <PerformanceTitleFilters
        hideQuarter={true}
        title="Design Insignia"
        subTitle="Track insignia on product designs."
        isCurrent={false}
        isAggregate={false}
      />

      <div className="panel panel-portal">
        <div className="panel-body">
          <InsigniaGraph
            loading={loading}
            insignia={insingia}
          />

        </div>
      </div>
    </FullContent>
  );
};
