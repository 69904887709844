import * as React from 'react';
import { RoyaltySchedule } from '../../shared';

interface IProps {
  schedule: RoyaltySchedule;
  hasStandardRoyalty: boolean;
}

class RoyaltyScheduleTemplate extends React.PureComponent<IProps, any> {

  static defaultProps = {
    hasStandardRoyalty: true,
  };

  public render() {

    const schedule = this.props.schedule;

    const dateLabel = (schedule : RoyaltySchedule) => {
      if (schedule.rateSalesStartingDate || schedule.rateSalesEndingDate) {

        return <strong> on {schedule.isDefaultSchedule ? '' :  'applicable'} sales {schedule.rateSalesStartingDate ? `begining ${schedule.rateSalesStartingDate.format('MMMM D, Y')}` : ''} {schedule.rateSalesEndingDate ? `through ${schedule.rateSalesEndingDate.format('MMMM D, Y')}` : ''}</strong>;

      }
      return null;
    };

    const isNonZero = (quantity: string|number) => quantity && quantity !== '0.00';

    const customRoyaltyRateSection = schedule.isCustom ? (
      <div>
        <strong>Royalty Rate</strong>:{' '}
        <br />
        <strong>
          {this.props.schedule.description}
          {dateLabel(schedule)}
        </strong>
      </div>
    ) : null;

    const royaltyExemptSection = schedule.isExempt ? (
      <div>
        <strong>Royalty Rate</strong>:
        <br />
        <strong>
          Royalty Exempt
        </strong>
      </div>
    ) : null;

    const unitDefinitionText = schedule.unitDefinition ? schedule.unitDefinition : 'per unit';

    const rates: string[] = [];
    if (isNonZero(schedule.royaltyRate)) {
      rates.push(`${schedule.royaltyRate}% ${schedule.percentageDescription}`);
    }
    if (isNonZero(schedule.flatRoyaltyPerUnit)) {
      rates.push(`\$${schedule.flatRoyaltyPerUnit} ${unitDefinitionText}`);
    }
    if (isNonZero(schedule.minimumRoyaltyPerUnit)) {
      rates.push(`\$${schedule.minimumRoyaltyPerUnit} minimum royalty ${unitDefinitionText}`);
    }

    const ratesMarkup = rates.map((rateText: string, i) => (
      <span key={i}>{rateText}{i < (rates.length - 1) ? ', ' : null}</span>
    ));

    const canShowRoyaltyRateSection = !schedule.isDefaultSchedule || this.props.hasStandardRoyalty;
    const royaltyRateSection = canShowRoyaltyRateSection ? (
      <div>
        <strong>Royalty Rate</strong>:{' '}
        <br />
        <strong>
          {ratesMarkup}
          {dateLabel(schedule)}
        </strong>
      </div>
    ) : null;

    const canShowMinimumRoyalty = isNonZero(schedule.minimumGuarantee);
    const minimumRoyaltySection = canShowMinimumRoyalty ? (
      <div>
        <br />
        <strong>Minimum Royalty</strong>:
        <br/>
        <strong>
          ${schedule.minimumGuarantee}
          {schedule.minimumStartingYear && schedule.minimumEndingYear ? `for contract year beginning July 1, ${schedule.minimumStartingYear} through June 30, ${schedule.minimumEndingYear + 1}` : ''}
          {schedule.minimumStartingYear && !schedule.minimumEndingYear ? ` starting with contract year beginning July 1, ${schedule.minimumStartingYear}` : ''}
          {!schedule.minimumStartingYear && schedule.minimumEndingYear ? ` for contract year through June 30, ${schedule.minimumEndingYear + 1}` : ''}
        </strong>
      </div>
    ) : null;

    const productCategoriesText = schedule.productCategories.length > 0 ? (
      schedule.productCategories.map(category => (category.name)).join(', ')
    ) : 'All Product Categories';

    const channelText = schedule.distributionChannels.length > 0 ? (
        schedule.distributionChannels.map(channel => channel.name).join(', ')
    ) : 'All Distribution Channels';

    const insigniaText = schedule.insignia.length > 0 ? (
        schedule.insignia.map(mark => mark.title).join(', ')
    ) : 'All Insignia';

    const restrictionsSection = schedule.isDefaultSchedule ? null : (
      <div>
        <div>
          <strong>Applicable Product Categories</strong>: {productCategoriesText}
        </div>
        <div>
          <strong>Applicable Insignia of LICENSOR</strong>: {insigniaText}
        </div>
        <div>
          <strong>Applicable Distribution Channels</strong>: {channelText}
        </div>
      </div>
    );

    return (
      <div className="well well-lg" style={{ fontFamily: 'monospace' }}>
        <h3 style={{ marginTop: 0 }}>{this.props.schedule.title}</h3>
        {restrictionsSection}
        <br />

        {customRoyaltyRateSection}
        {royaltyExemptSection}
        {!schedule.isCustom && !schedule.isExempt ? (
          <div>
            {royaltyRateSection}
            {minimumRoyaltySection}
          </div>
        ) : (
          null
        )}
      </div>
    );
  }
}

export default RoyaltyScheduleTemplate;
