import Axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { FullContent } from '../../ContentFrame';
import { GatewayModal, LoadingSpinner, ModalType } from '../../shared';

interface KeywordAPIData {
  id: number;
  alias: string;
  is_regex: boolean;
}

export const KeywordsPage = () => {
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [keywords, setKeywords] = useState<KeywordAPIData[] | []>([]);
  const [deleteModalShown, setDeleteModalShown] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const routeParams = useParams();

  useEffect(() => {
    getAccountMappingAliases();
  },        []);

  function getAccountMappingAliases() {
    setLoading(true);
    const params = {
      account_id: getAccountID(),
    };

    Axios.get('/api/keywords', { params })
      .then((response) => {
        setKeywords(response.data);
        setLoading(false);
      });
  }

  function deleteKeyword() {
    if (idToDelete) {
      setDeleting(true);
      const params = {
        account_id: getAccountID(),
        id: idToDelete,
      };
      Axios.delete('/api/keywords', { params })
        .then((response) => {
          setKeywords(keywords.filter(keyword => keyword.id !== idToDelete));
          setIdToDelete(0);
          getAccountMappingAliases();
          hideDeleteModal();
          setDeleting(false);
        })
        .catch(() => setDeleting(false));
    }
  }

  function showDeleteModal() {
    setDeleteModalShown(true);
  }

  function hideDeleteModal() {
    setDeleteModalShown(false);
  }

  function setDeleteID(e: any) {
    setIdToDelete(parseInt(e.target.getAttribute('data-id'), 10));
    showDeleteModal();
  }

  function getAccountID() {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['licensorId']) {
      return routeParams['licensorId'];
    }
    return '';
  }

  let pageContent;
  const rows = (keywords as any[]).map(keyword =>
    <tr key={ keyword.id } className="licensor-mapping-row">
      <td style={{ verticalAlign: 'middle' }}>
        {keyword.alias}
        { keyword.is_regex &&
          <span className="label label-primary" style={{ marginLeft: 15 }}>REGEX</span>
        }
      </td>
      <td>
        <button className="btn btn-link text-danger pull-right" data-id={keyword.id} onClick={setDeleteID}>
          Delete
        </button>
      </td>

    </tr>,
  );

  if (loading) {
    pageContent = (
      <LoadingSpinner />
    );
  } else if (keywords.length === 0 && ! loading) {
    pageContent =  (
      <em>No Keywords Found.</em>
    );
  } else {
    pageContent = (
      <table className="table">
        <tbody>
        {rows}
        </tbody>
      </table>
    );
  }

  const deleteModalText = deleting ? 'Deleting...' : 'Delete Keyword' ;

  return (
    <FullContent breadcrumbs={[{ name: 'Keywords' }]}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

        <div style={{ maxWidth: '50%' }}>
          <h3><strong>Keywords</strong></h3>
          <p className="text-muted">Manage unique terms that automatically link with this account.</p>
        </div>
        <Link to={'keywords/add'} className="btn btn-primary">Add Keyword</Link>
      </div>
      <div className="panel panel-portal">
        { pageContent }
      </div>
      <GatewayModal shown={deleteModalShown} type={ModalType.Danger} onClose={hideDeleteModal} title="Delete Keyword" >
        <Modal.Body>
          <p className="text-center">
            <strong>Are you sure you want to delete this keyword?</strong><br/>
            <strong className="text-danger">Deleting this keyword will clear/reset all matching retail sales.</strong>
          </p>

        </Modal.Body>
        <Modal.Footer>
          <button onClick={hideDeleteModal} className="btn btn-default pull-left">Cancel</button>
          <button onClick={deleteKeyword} className="btn btn-danger pull-right" disabled={deleting}>{deleteModalText}</button>
        </Modal.Footer>
      </GatewayModal>
    </FullContent>

  );

};
