import * as React from 'react';
import { Link } from 'react-router-dom';
import { Design } from '../../../shared';

interface IProps {
  vendorImage: string;
  image: string;
  url: string;
  netSales: number;
  royaltySales: number;
  quantity: number;
  salesOnClick: (i: number) => any;
  design: Design;
}

export class TopSellingDesignGridItem extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
  }

  handleSalesDataClick(designId:number) {
    this.props.salesOnClick(designId);
  }

  render() {
    return (
      <div className="panel panel-portal panel-portal-hover">
        <Link style={{ textDecoration: 'none' }} to={this.props.url}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              flexDirection: 'column',
            }}
            className="panel-body"
          >
            <div className="text-center text-ellipsis text-primary" style={{ width: '100%', fontSize: 12, paddingBottom:5 }}>
              {this.props.design.vendor.shortName}
            </div>
            <img style={{ minHeight: 50, maxHeight: 50 }} className="img-responsive" src={this.props.vendorImage} />

            <img style={{ minHeight: 150, maxHeight: 150 }} className="img-responsive" src={this.props.image} />
          </div>
        </Link>
        <div style={{ paddingTop: 0, paddingBottom: 5 }} className="panel-body">
          <hr style={{ margin: 5 }}/>
          <div style={{ fontSize: 12 }} className=" text-ellipsis">
            <span className="text-muted pull-left">Sales</span> <strong className="pull-right">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.props.netSales)}</strong>
          </div>
          <hr style={{ margin: 5 }}/>
          <div style={{ fontSize: 12 }} className=" text-ellipsis">
            <span className="text-muted pull-left">Royalties</span> <strong className="pull-right">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.props.royaltySales)}</strong>
          </div>
          <hr style={{ margin: 5 }}/>
          <div style={{ fontSize: 12 }} className=" text-ellipsis">
            <span className="text-muted pull-left">Units</span> <strong className="pull-right">{this.props.quantity}</strong>
          </div>
            <button onClick={ () => this.handleSalesDataClick(this.props.design.id) } style={{ fontSize: 12 }} className="text-center btn btn-block btn-link">View Sales Data</button>
        </div>
      </div>
    );

  }

}
