import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface IProps {
  header: string;
  subHeader: string;
  info?: boolean;
  infoOnClick?: () => void;
}

export const ChartHeader = (props: IProps) => {
  const info = props.info ?
    <FontAwesomeIcon
      style={{ cursor: 'pointer' }}
      className="text-primary"
      icon={faInfoCircle}
      onClick={props.infoOnClick}
    /> : null;
  return (
    <div className="performance-chart-header">
      <div>
        <strong>{props.header} {info}</strong>
        <p className="text-muted small">{props.subHeader}</p>
      </div>
    </div>

  );

};
