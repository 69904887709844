import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';

export class Filters {
  defaultFilters = {
    search: '',
    vendorId: null,
    userId: null,
    phaseId: null,
    page: 1,
    perPage: 24,
    quarter: '',
    sortBy: '',
    sortDir: 'desc',
    status: 1,
  };
  search: string = '';
  perPage: number = 24;
  page: number = 1;
  sortBy: string = '';
  vendorId: number;
  licensorId: number;
  startDate: string = '';
  endDate: string = '';
  market: string = '';
  userId: number;

  status: number = 1;

  constructor(defaultFilterValues?: any) {
    if (defaultFilterValues) {
      this.defaultFilters = defaultFilterValues;
    }

  }

  setQueryParamsFromLocation(location: string) {
    const queryValues = queryString.parse(location);
    this.search = queryValues.search ? queryValues.search : this.search;
    this.search = queryValues.keyword ? queryValues.keyword : this.search;
    this.perPage = queryValues.limit ? queryValues.limit : this.perPage;
    this.page = queryValues.page ? Number(queryValues.page) : this.page;
    this.status = queryValues.status !== undefined ? Number(queryValues.status) : this.status;
    this.endDate = queryValues.end_date ? queryValues.end_date : this.endDate;
    this.startDate = queryValues.start_date ? queryValues.start_date : this.startDate;
    this.market = queryValues.market ? queryValues.market : this.market;
    this.userId = queryValues.user ? queryValues.user : this.userId;
  }

  generateQueryParamString() {
    const queryParams = {
      search: this.search.length ? this.search : null,
      start_date: this.startDate.length ? this.startDate : null,
      end_date: this.endDate.length ? this.endDate : null,
      page: this.page !== 1 ? this.page : null,
      market: this.market.length ? this.market : null,
      user: this.userId ? this.userId : null,
      sort: this.sortBy.length ? this.sortBy : null,
    };
    const cleanParams = omitBy(queryParams, isNil);
    const qs = queryString.stringify(cleanParams);
    return qs;
  }

  setFilters(filters: any) {
    const newFilters = {
      search : this.search,
      perPage: this.perPage,
      page: this.page,
      sortBy: this.sortBy,
      status: this.status,
      startDate: this.startDate,
      endDate: this.endDate,
      market: this.market,
      userId: this.userId,
      ...filters,
    };
    this.search = newFilters.search;
    this.perPage = newFilters.perPage;
    this.page = newFilters.page;
    this.status = newFilters.status;
    this.startDate = newFilters.startDate;
    this.endDate = newFilters.endDate;
    this.market = newFilters.market;
    this.userId = newFilters.userId;
    this.sortBy = newFilters.sortBy;

  }

  getQueryParams() {
    const queryProps = {
      search: this.search !== this.defaultFilters.search ? this.search : null,
      limit: this.perPage !== this.defaultFilters.perPage ? this.perPage : null,
      page: this.page !== this.defaultFilters.page ? this.page : null,
      status: this.status !== this.defaultFilters.status ? this.status : null,
    };
    Object.keys(queryProps).forEach(key => (queryProps[key] === null) && delete queryProps[key]);
    return queryProps;
  }

  get allFilters() {
    return {
      page: this.page,
      perPage: this.perPage,
      sortBy: this.sortBy,
      search: this.search,
      startDate: this.startDate,
      endDate: this.endDate,
      market: this.market,
      userId: this.userId,
    };
  }

}

export const getInitialFilters = (search: string, sortBy: string): Filters => {
  const filters = new Filters();
  filters.setQueryParamsFromLocation(search);
  return filters;
};
