import Axios from 'axios';
import * as mapboxgl from 'mapbox-gl';
import * as querystring from 'query-string';
import * as React from 'react';
import { useLocation } from 'react-router';
import { states } from '../../shared';
import { LayerInfo } from '../Audience';
import { FullContent } from '../ContentFrame';
import { AffinityMap, PaginationFooter, useCancelToken } from '../shared';
import { HiddenGeoDataModule, HiddenSalesDataModule } from './HiddenPerformanceModule';
import { PerformanceTitleFilters } from './PerformanceTitleFilters';
import { CategoryPerformance, ChannelPerformance, GeolocationPerformance, HistoricalPerformance, PerformanceStats, RetailerPerformance, VendorPerformance } from './submodules';
import { apiToPerformanceStats, DefaultPerformanceStats, VendorRetailerStats } from './submodules/shared';
import { usePerformanceQueryParams } from './usePerformanceQueryParams';

export const RetailersPerformancePage = () => {
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [stats, setStats] = React.useState<PerformanceStats>(DefaultPerformanceStats);
  const [loading, setLoading] = React.useState(true);

  const location = useLocation();
  const query = querystring.parse(location.search);
  const performanceQP = usePerformanceQueryParams();
  const isFiltered = query['retailer'] ? true : false;
  const cancelToken = useCancelToken();

  const map = React.useRef<any>(null);
  const [mapData, setMapData] = React.useState<any>({
    sales: null,
    mapDefaults: null,
  });
  const [loadingMap, setLoadingMap] = React.useState(false);
  const [layers, setLayers] = React.useState<LayerInfo[]>([]);
  const retailerClickRef = React.useRef<any>(null);

  React.useEffect(() => {
    getRetailers();
    performanceQP.scrollToTop();
  },              [page]);
  React.useEffect(() => {
    if (isFiltered) {
      getData();
    } else {
      getRetailers();
    }
    performanceQP.scrollToTop();
    getRetailersMap();

  },              [location.search]);

  const setStateCenter = () => {
    if (performanceQP.params.state) {
      const foundState = states.find(s => s.shortName === performanceQP.params.state);
      if (foundState) {
        map.current.setCenter(foundState.lng, foundState.lat);
        map.current.setZoom(5);
      }
    }  else {
      map.current.setCenter(-98.583333, 39.833333);
      map.current.setZoom(2.75);

    }
  };

  const getRetailersMap = async () => {
    setLoadingMap(true);
    const s = await Axios.get(`/api/audience/map?withRetailers=1&${performanceQP.toMapApi('geo')}`);
    setMapData({
      retailers: s.data.retailers.data,
      mapDefaults: s.data.default,
    });
    setRetailersLayer(s.data.retailers.data);
    setLoadingMap(false);

  };

  const onMapLoad = () => {
    if (map.current.map) {
      setStateCenter();

    }
  };

  const onRetailerClick = (e: any) => {
    const popups = document.getElementsByClassName('mapboxgl-popup');
    if (popups.length > 0) {
      for (let i = 0; i < popups.length; i++) {
        popups[i].remove();
      }
    }
    const retailerPopup = new mapboxgl.Popup();
    let retailerHtml = `<strong>${e.features[0].properties.short_name}</strong><br>
                                ${e.features[0].properties.address}<br>`;
    if (e.features[0].properties.icon_url) {
      retailerHtml = `<div style="height: 100px"><img src="${ e.features[0].properties.icon_url}" style="height: 100px" class="center-block"></div>${retailerHtml}`;
    }
    if (e.features[0].properties.is_business_address === '1') {
      retailerHtml = `${retailerHtml}<span style="display: block;margin: 5px;" class="label label-primary">Business Address</span>`;
    }
    if (e.features[0].properties.is_manufacturing_factory === '1') {
      retailerHtml = `${retailerHtml}<span style="display: block;margin: 5px;" class="label label-default">Manufacturing</span>`;
    }
    if (e.features[0].properties.is_retail_storefront === '1') {
      retailerHtml = `${retailerHtml}<span style="display: block;margin: 5px;" class="label label-default">Retail</span>`;
    }
    if (e.features[0].properties.is_fulfillment_center === '1') {
      retailerHtml = `${retailerHtml}<span style="display: block;margin: 5px;" class="label label-default">Fulfillment</span>`;
    }
    if (e.features[0].properties.is_office === '1') {
      retailerHtml = `${retailerHtml}<span style="display: block;margin: 5px;" class="label label-default">Office</span>`;
    }
    retailerHtml = `<div class="text-center">${retailerHtml}</div>`;
    retailerPopup
      .setLngLat(e.lngLat)
      .setHTML(retailerHtml)
      .addTo(map.current.map);
  };

  const setRetailersLayer = (retailers: any) => {
    map.current.removeLayers(layers);
    if (retailers.linked) {
      map.current.map.loadImage(
        '/assets/images/marker-icons/mapbox-marker-icon-retailer-blue.png',
        (error: any, defaultImage: any) => {
          if (error) throw error;
          const retailerLayerInfo = [];

          const retailersInfo: LayerInfo = {
            id: 'retailers-linked-marker',
            name: 'retailers-linked',
            sourceName: 'retailers-linked-source',
            layerName: 'retailers-linked-layer',
            isChecked: true,
          };

          map.current.map.addSource(retailersInfo.sourceName, {
            type: 'geojson',
            data: retailers.linked.retailerJson,
          });

          map.current.map.addImage(retailersInfo.id, defaultImage);
          map.current.map.addLayer({
            id: retailersInfo.layerName,
            type: 'symbol',
            source: retailersInfo.sourceName,
            visibility: 'visible',
            layout: {
              'icon-image': retailersInfo.id,
              'icon-allow-overlap': true,
            },
          });

          retailerClickRef.current = onRetailerClick;
          map.current.map.on('click', retailersInfo.layerName, retailerClickRef.current);
          map.current.map.on('mouseenter', retailersInfo.layerName, () => {
            map.current.map.getCanvas().style.cursor = 'pointer';
          });
          map.current.map.on('mouseleave', retailersInfo.layerName, () => {
            map.current.map.getCanvas().style.cursor = '';
          });

          retailerLayerInfo.push(retailersInfo);
          setLayers(retailerLayerInfo);
        });
    }

  };

  const getData = async () => {
    if (cancelToken) {
      cancelToken.cancelToken();
    }
    setLoading(true);
    const token = Axios.CancelToken.source();
    cancelToken.setToken(token);
    const queryParams = performanceQP.toApi('retailer');
    const analytics = await Axios.get(`/api/performance-reports/sales-data?${queryParams}`, { cancelToken: token.token })
      .catch((e) => {
        setLoading(false);
        cancelToken.setToken(null);
      });
    if (analytics) {
      const s = apiToPerformanceStats(analytics.data.data);
      setStats(s);
      setLoading(false);
    }
    cancelToken.setToken(null);

  };

  const getRetailers = async () => {
    setLoading(true);
    const queryParams = performanceQP.toApi('retailer', [{ value: page, label: 'page' }]);
    const analytics = await Axios.get(`/api/performance-reports/retailers?${queryParams}`);
    const retailers: VendorRetailerStats[] = analytics.data.data.retailers.map((v: any) => {
      return {
        name: v.title,
        id: v.id,
        image: v.image,
        amount: Number(v.amount),
        previousAmount: v.previous ? Number(v.previous.amount) : 0,
        percent: v.percent * 100,
        rpu: Number(v.rpu),
        units: Number(v.quantity),
        sales: Number(v.gross_sales),
      };
    });
    const s = stats;
    s.retailers = retailers;
    s.isCurrent = analytics.data.data.is_current;
    setStats(s);

    setTotalPages(analytics.data.data.totalPages);

    setLoading(false);

  };

  const changePage = (p: number) => {
    setLoading(true);
    setPage(p);
  };

  let additionalGraphs;
  if (isFiltered) {
    additionalGraphs = (<div>

      {stats.salesDataDisplayable ? <div>
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-portal">
              <div className="panel-body">
                <VendorPerformance
                  vendors={stats.vendors}
                  loading={loading}
                  large={false}
                  isAggregate={stats.isAggregate}
                />
              </div>
            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-portal">
              <div className="panel-body">
                <CategoryPerformance
                  categories={stats.categories}
                  loading={loading}
                  large={false}
                  isAggregate={stats.isAggregate}
                />
              </div>

            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-portal">
              <div className="panel-body">
                <ChannelPerformance
                  channels={stats.channels}
                  loading={loading}
                  large={false}
                  isAggregate={stats.isAggregate}
                />
              </div>
            </div>

          </div>
        </div>

      </div> : <div className="panel panel-portal">
        <div className="panel-body">
          <HiddenSalesDataModule />
        </div>
      </div>}
      {stats.geoDisplayable ? <div>

        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-portal">
              <div className="panel-body">
                <GeolocationPerformance
                  large={false}
                  loading={loading}
                  states={stats.states}
                  cities={stats.cities}
                  section="map"
                  isAggregate={stats.isAggregate}
                />

              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-portal">
              <div className="panel-body">
                <GeolocationPerformance
                  large={false}
                  loading={loading}
                  states={stats.states}
                  cities={stats.cities}
                  section="states"
                  isAggregate={stats.isAggregate}
                />

              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-portal">
              <div className="panel-body">
                <GeolocationPerformance
                  large={false}
                  loading={loading}
                  states={stats.states}
                  cities={stats.cities}
                  section="cities"
                  isAggregate={stats.isAggregate}
                />

              </div>
            </div>
          </div>
        </div>
      </div> : <div className="panel panel-portal">
        <div className="panel-body">
          <HiddenGeoDataModule />
        </div>
      </div> }

    </div>);

  }

  return (
    <FullContent>
      <PerformanceTitleFilters
        title="Retailers"
        subTitle="Sales by recognized retailer"
        isAggregate={stats.isAggregate}
        isCurrent={stats.isCurrent}
      />
      <div className="panel panel-portal">
        <div className="panel-body">
          <AffinityMap useDefaultCenter={true} fullParams={performanceQP.toFullRetailerMap()} isFullScreen={false} ref={map} height={300} width="100%" loading={loadingMap} onMapLoad={onMapLoad}/>

        </div>
      </div>
      {(stats.salesDataDisplayable && isFiltered) || loading ? <div className="panel panel-portal">
        <div className="panel-body">
          <HistoricalPerformance
            loading={loading}
            historicalData={stats.historicalData}
          />
        </div>
      </div> : null}
      {(stats.salesDataDisplayable || isFiltered) || loading ? <div>
        <div className="panel panel-portal">
          <div className="panel-body">
            <RetailerPerformance
              retailers={stats.retailers}
              loading={loading}
              large={true}
              isAggregate={stats.isAggregate}
              page={page}
            />
          </div>
        </div>

        {!isFiltered ? <PaginationFooter hasNext={totalPages > page} currentPage={page} setPage={changePage} /> : null}
      </div> : <div className="panel panel-portal">
        <div className="panel-body">
          <HiddenSalesDataModule />
        </div>
      </div>}
      {additionalGraphs}
    </FullContent>
  );

};
